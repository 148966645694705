import url from '@fundrocket/common-web/libs/url'

const isPay = (_url) =>
  url.getDomain(_url) === 'localhost' ||
  url.getDomain(_url) === 'dev.fundrocket' ||
  url.getSubdomain(_url) === 'pay' ||
  url.getSubdomain(_url) === 'stg-ui.pay'

export default {
  CAMPAIGN_ID:
    process.env.GATSBY_FR_RELEASE_LEVEL === 'prod'
      ? '4794a503-bc00-4dd5-aaab-4e895c7c8d33'
      : '2f9d2083-757f-4aa3-ba08-edf512676f5c',
  isPay,
}
