import PropTypes from 'prop-types'
import React from 'react'

import Box from '@fundrocket/common-web/components/Box'
import Button from '@fundrocket/common-web/components/Button'
import Columns from '@fundrocket/common-web/components/Columns'
import Container from '@fundrocket/common-web/components/Container'
import Link from '@fundrocket/common-web/components/Link'
import Module3 from '@fundrocket/common-web/components/Module3'
import PlaidLink from '@fundrocket/common-web/components/PlaidLink2'
import Row from '@fundrocket/common-web/components/Row'
import Text from '@fundrocket/common-web/components/Text'
import Title from '@fundrocket/common-web/components/Title'

import emails from 'constants/emails'

function BankButtonModalBox(props) {
  return (
    <Box size="small">
      <Module3 {...props} align="center" />
    </Box>
  )
}
export default function BankButtonModal({ buttonInstantProps, buttonManualProps, close }) {
  function handleButtonInstantClick() {
    close()
  }

  return (
    <div className="BankButtonModal">
      <Title align="center">How would you like to verify your bank?</Title>
      <Container flush size="large">
        <Row size="small">
          <Columns>
            <BankButtonModalBox
              title="Instant verification"
              button={
                <PlaidLink
                  {...buttonInstantProps}
                  level="primary"
                  size="small"
                  onClick={handleButtonInstantClick}>
                  Instant
                </PlaidLink>
              }
              align="center">
              Securely sign into your bank.
              <br />
              Verified instantly.
            </BankButtonModalBox>
            <BankButtonModalBox
              title="Manual verification"
              button={<Button {...buttonManualProps}>Manual</Button>}
              align="center">
              Provide bank documents.
              <br />
              Can take up to 4 days.
            </BankButtonModalBox>
          </Columns>
        </Row>
        <Text align="center" element="p" flush size="small">
          To manually verify your bank account, a member of our team will be reaching out to you to
          complete the process. For any questions, please email <Link to={emails.PAY_SUPPORT} />.
        </Text>
      </Container>
    </div>
  )
}

BankButtonModal.propTypes = {
  buttonInstantProps: PropTypes.object.isRequired,
  buttonManualProps: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
}
