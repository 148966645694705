import { useField } from 'formik'
import React from 'react'

import copy from '@fundrocket/common/constants/copy'
import urls from '@fundrocket/common/constants/urls'
import validations from '@fundrocket/common/libs/validations'
import Display from '@fundrocket/common-web/components/Display'
import Field2 from '@fundrocket/common-web/components/Field2'
import HelpIcon from '@fundrocket/common-web/components/HelpIcon'
import InputEinTin from '@fundrocket/common-web/components/InputEinTin'
import Link from '@fundrocket/common-web/components/Link'

export default function FieldEinTin2(props) {
  const [value, setValue] = React.useState(props.value || '')
  const [valueNonRedacted, setValueNonRedacted] = React.useState(props.value)

  const name = 'einTin'

  const [field, meta, helpers] = useField(name)

  function handleChange(e) {
    const { value: currentValue } = e.currentTarget

    setValue(currentValue)
    helpers.setValue(currentValue)
  }

  async function handleBlur(e) {
    const { value: currentValue } = e.currentTarget

    setValueNonRedacted(currentValue)

    try {
      const isValid = await validations.einTin.isValid(currentValue)

      if (isValid) {
        // ##-####### -> ••-•••####
        setValue(
          `${currentValue
            .substring(0, currentValue.length - validations.EIN_TIN_NONREDACTED_CHARS_LENGTH)
            .replace(/\d/g, '•')}${currentValue.slice(-validations.EIN_TIN_NONREDACTED_CHARS_LENGTH)
          }`
        )
      }
    } catch(error) {

    }
  }

  function handleFocus() {
    setValue(valueNonRedacted)
  }

  return (
    <Field2
      as={InputEinTin}
      label="EIN or TIN"
      labelNote={
        <>
          {copy.EIN_TIN_TOOLTIP}
          <br />
          <Link to={urls.IRS_EIN} weight="semibold">
            Learn more
          </Link>
        </>
      }
      labelTooltip={
        <Display element="span" hide="mobile">
          <HelpIcon to={urls.IRS_EIN} tooltip={copy.EIN_TIN_TOOLTIP} />
        </Display>
      }
      name={name}
      value={value}
      // keep order
      {...props}
      className="FieldEinTin2"
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  )
}