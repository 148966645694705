import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Container from '@fundrocket/common-web/components/Container'
import View from '@fundrocket/common-web/components/View'

import ErrorBoundary from 'components/ErrorBoundary'
import Header from 'components/Header'
import Layout from 'components/Layout'

export default class AppView extends React.Component {
  componentDidMount() {
    reporter.setEventsContext({ url: window.location.toString() })
  }

  render() {
    const { children, className } = this.props

    return (
      <Layout isApp>
        <div className={classNames('AppView', className)}>
          <Header />
          <View>
            <Container size="medium">
              <ErrorBoundary>{children}</ErrorBoundary>
            </Container>
          </View>
        </div>
      </Layout>
    )
  }
}

AppView.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}
