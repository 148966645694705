import React from 'react'

import notifications from '@fundrocket/common-web/libs/notifications'

import AppView from 'components/AppView'
import TermsForm from 'components/TermsForm'
import paths from 'constants/paths'

export default function Terms() {
  function handleSuccess() {
    notifications.notifyAndReload({
      level: 'success',
      message: 'Thanks for agreeing to updated terms',
      to: paths.DASHBOARD,
    })
  }

  return (
    <AppView className="Terms">
      <TermsForm onSuccess={handleSuccess} />
    </AppView>
  )
}
