// export const EMAIL_SUBMIT = 'EmailSubmitted'
// export const FUTURE_OFFER_ATTEMPT = 'FutureOfferRedeemAttempted'
// export const INVALID_OFFER_INPUT = 'InvalidOfferInput'
// export const OFFER_ALREADY_APPLIED = 'OfferAlreadyApplied'
// export const OFFER_EXPIRED = 'OfferExpired'
// export const REDEEM_CLICK = 'RedeemedCode'
// export const SELECT_OFFER = 'OfferSelected'

// export const VISIT_APPLICATION_REFERRED = 'ApplicationReferredVisited'
// export const PERSONAL_DETAILS_FILLED_REFERRED = 'ApplicationReferred-PersonalDetailsFilled'
// export const COMPLETE_REQUEST_REFERRED = 'ApplicationReferred-RequestCompleted'
// export const BANK_ACCOUNT_SELECT_REFERRED = 'ApplicationReferred-BankAccountSelected'
// export const PHONE_VERIFY_CLICK_REFERRED = 'ApplicationReferred-PhoneVerifyClicked'
// export const PLAID_LINK_SUCCESS_REFERRED = 'ApplicationReferred-PlaidBankLinkSuccess'
// export const PLAID_EXIT_REFERRED = 'ApplicationReferred-PlaidExit'
// export const INCORRECT_PHONE_VERIFICATION_CODE_REFERRED = 'ApplicationReferred-IncorrectPhoneVerificationCodeEntered'
// export const PHONE_VERIFICATION_CODE_LIMIT_REFERRED = 'ApplicationReferred-PhoneVerificationCodeEnteredRateLimited'
// export const PHONE_VERIFIED_REFERRED = 'ApplicationReferred-PhoneVerified'

// export const VISIT_APPLICATION_RENEWAL = 'ApplicationRenewalVisited'
// export const COMPLETE_REQUEST_RENEWAL = 'ApplicationRenewal-RequestCompleted'
// export const PHONE_VERIFICATION_START_RENEWAL = 'ApplicationRenewal-PhoneVerificationStart'
// export const PHONE_VERIFY_CLICK_RENEWAL = 'ApplicationRenewal-PhoneVerifyClicked'
// export const INCORRECT_PHONE_VERIFICATION_CODE_RENEWAL = 'ApplicationRenewal-IncorrectPhoneVerificationCodeEntered'
// export const PHONE_VERIFICATION_CODE_LIMIT_RENEWAL = 'ApplicationRenewal-PhoneVerificationCodeEnteredRateLimited'
// export const PHONE_VERIFIED_RENEWAL = 'ApplicationRenewal-PhoneVerified'

// export const VISIT_APPLICATION_OUTBOUND = 'ApplicationOutboundVisited'
// export const PERSONAL_DETAILS_FILLED_OUTBOUND = 'ApplicationOutbound-PersonalDetailsFilled'
// export const COMPLETE_REQUEST_OUTBOUND = 'ApplicationOutbound-RequestCompleted'
// export const BANK_ACCOUNT_SELECT_OUTBOUND = 'ApplicationOutbound-BankAccountSelected'
// export const BUSINESS_DETAILS_FILLED_OUTBOUND = 'ApplicationOutbound-BusinessDetailsFilled'
// export const PHONE_VERIFY_CLICK_OUTBOUND = 'ApplicationOutbound-PhoneVerifyClicked'
// export const PLAID_LINK_SUCCESS_OUTBOUND = 'ApplicationOutbound-PlaidBankLinkSuccess'
// export const PLAID_EXIT_OUTBOUND = 'ApplicationOutbound-PlaidExit'
// export const INCORRECT_PHONE_VERIFICATION_CODE_OUTBOUND = 'ApplicationOutbound-IncorrectPhoneVerificationCodeEntered'
// export const PHONE_VERIFICATION_CODE_LIMIT_OUTBOUND = 'ApplicationOutbound-PhoneVerificationCodeEnteredRateLimited'
// export const PHONE_VERIFIED_OUTBOUND = 'ApplicationOutbound-PhoneVerified'

// export const VISIT_APPLICATION_INBOUND = 'ApplicationInboundVisited'
// export const COMPLETE_REQUEST_INBOUND = 'ApplicationInbound-RequestCompleted'
// export const BANK_ACCOUNT_SELECT_INBOUND = 'ApplicationInbound-BankAccountSelected'
// export const PERSONAL_DETAILS_FILLED_INBOUND = 'ApplicationInbound-PersonalDetailsFilled'
// export const BUSINESS_DETAILS_FILLED_INBOUND = 'ApplicationInbound-BusinessDetailsFilled'
// export const PHONE_VERIFY_CLICK_INBOUND = 'ApplicationInbound-PhoneVerifyClicked'
// export const PLAID_LINK_SUCCESS_INBOUND = 'ApplicationInbound-PlaidBankLinkSuccess'
// export const PLAID_EXIT_INBOUND = 'ApplicationInbound-PlaidExit'
// export const INCORRECT_PHONE_VERIFICATION_CODE_INBOUND = 'ApplicationInbound-IncorrectPhoneVerificationCodeEntered'
// export const PHONE_VERIFICATION_CODE_LIMIT_INBOUND = 'ApplicationInbound-PhoneVerificationCodeEnteredRateLimited'
// export const PHONE_VERIFIED_INBOUND = 'ApplicationInbound-PhoneVerified'

// export const VISIT_APPLICATION_BANKLINK = 'ApplicationBankLinkVisited'
// export const COMPLETE_REQUEST_BANKLINK = 'ApplicationBankLink-RequestCompleted'
// export const BANK_ACCOUNT_SELECT_BANKLINK = 'ApplicationBankLink-BankAccountSelected'
// export const BUSINESS_DETAILS_FILLED_BANKLINK = 'ApplicationBankLink-BusinessDetailsFilled'
// export const PHONE_VERIFICATION_START_BANKLINK = 'ApplicationBankLink-PhoneVerificationStart'
// export const PHONE_VERIFY_CLICK_BANKLINK = 'ApplicationBankLink-PhoneVerifyClicked'
// export const PLAID_LINK_SUCCESS_BANKLINK = 'ApplicationBankLink-PlaidBankLinkSuccess'
// export const PLAID_EXIT_BANKLINK = 'ApplicationBankLink-PlaidExit'
// export const INCORRECT_PHONE_VERIFICATION_CODE_BANKLINK = 'ApplicationBankLink-IncorrectPhoneVerificationCodeEntered'
// export const PHONE_VERIFICATION_CODE_LIMIT_BANKLINK = 'ApplicationBankLink-PhoneVerificationCodeEnteredRateLimited'
// export const PHONE_VERIFIED_BANKLINK = 'ApplicationBankLink-PhoneVerified'

// export const VISIT_LANDING_PAGE = 'LandingPageVisited'
// export const VISIT_OFFER_PAGE = 'OfferPageVisited'
// export const VISIT_OFFER_REDEEM_PAGE = 'OfferRedeemPageVisited'

export default {
  OFFER_VIEWED: 'OfferViewed',

  EMAIL_SUBMITTED: 'EmailSubmitted',

  PLAID_LINK_START: 'PlaidLinkStarted',
  PLAID_LINK_ERROR: 'PlaidLinkFailed',
  PLAID_LINK_SUCCESS: 'PlaidLinkSucceeded',
  PLAID_UPDATE_START: 'PlaidUpdateStarted',
  PLAID_UPDATE_SUCCESS: 'PlaidUpdateSucceeded',

  PHONE_VERIFICATION_START: 'PhoneVerificationStarted',
  PHONE_VERIFICATION_RESEND: 'PhoneVerificationResentCode',
  PHONE_VERIFICATION_SUCCESS: 'PhoneVerificationSucceeded',

  APPLICATION_OFFER_SELECTED: 'ApplicationOfferSelected',
  APPLICATION_CONTACT_INFO_PROVIDED: 'ApplicationContactInformationProvided',
  APPLICATION_BUSINESS_INFO_PROVIDED: 'ApplicationBusinessInformationProvided',
  APPLICATION_PERSONAL_INFO_PROVIDED: 'ApplicationPersonalInformationProvided',
  APPLICATION_BANK_LINKED: 'ApplicationBankLinked',
  APPLICATION_SUBMITTED: 'ApplicationSubmitted',

  BANK_LINK_SUBMITTED: 'BankLinkSubmitted',

  MIXPANEL_ALIAS: 'MixpanelAlias',
  MIXPANEL_IDENTITY: 'MixpanelIdentify',
  MIXPANEL_PEOPLE_SET: 'MixpanelPeopleSet',

  SIGN_UP: 'SignUp',
}
