import React from 'react'

import { ENTITY_TYPE_OPTIONS } from '@fundrocket/common/constants/input-options'
import Field2 from '@fundrocket/common-web/components/Field2'
import InputSelect2 from '@fundrocket/common-web/components/InputSelect2'

export default function FieldEntityType2(props) {
  return (
    <Field2
      as={InputSelect2}
      entity="entity type"
      label="Entity Type"
      name="entityType"
      options={ENTITY_TYPE_OPTIONS}
      testid="entity-input"
      // keep order
      {...props}
      className="FieldEntityType2"
    />
  )
}
