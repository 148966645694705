import URI from 'urijs'

function getDomain(url) {
  if (!url) return null

  const uri = new URI(url)

  return uri.domain()
}

function getSecondaryDomain(url) {
  if (!url) return null

  const uri = new URI(url)
  let domain = uri.domain()
  const subdomain = uri.subdomain()

  if (subdomain && subdomain !== 'www') {
    domain = `${subdomain}.${domain}`

    return domain.replace(`.${uri.tld()}`, '')
  }

  return domain.replace(`.${uri.tld()}`, '')
}

function getSubdomain(url) {
  if (!url) return null

  const uri = new URI(url)
  const subdomain = uri.subdomain()

  if (subdomain && subdomain !== 'www') {
    return subdomain
  }

  return ''
}

export default {
  getDomain,
  getSecondaryDomain,
  getSubdomain,
}
