//import { navigate } from 'gatsby'
import { get } from 'lodash'
import React from 'react'
import Alert from 'react-s-alert'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Copy from '@fundrocket/common-web/components/Copy'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import PlaidLink from '@fundrocket/common-web/components/PlaidLink2'
import Row from '@fundrocket/common-web/components/Row'
import SecurityNotice from '@fundrocket/common-web/components/SecurityNotice'
import Text from '@fundrocket/common-web/components/Text'
import notifications from '@fundrocket/common-web/libs/notifications'

import AppView from 'components/AppView'
import Help from 'components/Help'
import events from 'constants/events'
import paths from 'constants/paths'
import urls from 'constants/urls'
import apiRequest from 'libs/api-request'
import bankAccounts from 'libs/bank-accounts'
import OfferService from 'services/OfferService'

const offerService = new OfferService()

export default class BankLink extends React.Component {
  state = {
    applicationId: null,
    disabled: null,
    plaid: null,
  }

  componentDidMount() {
    reporter.setEventsContext({ url: window.location.toString() })
    this.getApplicationId()
  }

  getApplicationId = () => {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      const application = response.data[0]

      if (!application) {
        notifications.notifyAndReload({
          message: 'You haven’t completed an application',
          to: paths.DASHBOARD,
          level: 'warning',
        })
        return
      }

      const { id: applicationId } = application

      this.setState({ applicationId })

      reporter.setEventsContext({ applicationId })
    })
  }

  handlePlaidExit = (error, metadata) => {
    if (metadata.status === 'institution_not_found') {
      Alert.warning(
        'We’re sorry we don’t have coverage for your bank. We will notify you if this changes.'
      )
    }

    if (error && error.error_code === 'ITEM_LOCKED' && error.error_type === 'ITEM_ERROR') {
      Alert.warning('Your account is locked! Visit your bank’s site and unlock your account.')
    }
  }

  handlePlaidSuccess = (publicKey, metadata) => {
    const { accounts } = metadata

    const bankAccount = bankAccounts.getAccount(accounts)

    if (!bankAccount) {
      Alert.warning(
        'We were unable to find a business checking account under the credentials provided. Please link your business checking account.'
      )
      return
    }

    this.setState(
      {
        disabled: true,
        plaid: {
          ...metadata,
          account: bankAccount,
          account_id: bankAccount.id,
        },
      },
      () => {
        reporter.trackEvent(events.APPLICATION_BANK_LINKED)
        this.submit()
      }
    )
  }

  submit = () => {
    const { applicationId, plaid } = this.state

    offerService
      .applyOfferBankUpdate({
        application_id: applicationId,
        banking_info: {
          data: plaid,
          provider: 'plaid',
        },
      })
      .then(() => {
        reporter.trackEvent(events.BANK_LINK_SUBMITTED)

        notifications.notifyAndReload({
          message: 'Thank you for updating your bank information!',
          to: paths.DASHBOARD,
          level: 'success',
        })
      })
      .catch((error) => {
        if (get(error, 'response.status') === 409) {
          // TODO
          //navigate(paths.APPLICATION_SUBMITTED)
        } else {
          this.setState({ disabled: false })
          Alert.error('There was a problem with your submission. Please try again later.')

          reporter.trackError(error)
        }
      })
  }

  render() {
    const { applicationId, disabled } = this.state

    return (
      <AppView className="BankLink">
        <Fieldset
          title="Link your business bank account"
          copy={
            <Copy>
              <Text element="p" flush>
                This allows us to:
              </Text>
              <ul>
                <li>Instantly verify your business</li>
                <li>Analyze historical balances</li>
                <li>Deposit your money</li>
              </ul>
            </Copy>
          }>
          <Row size="small">
            <PlaidLink
              eventPrefix="Pay-"
              disabled={disabled || !applicationId}
              level="primary"
              testid="bank-link--bank-auth--plaid-button"
              onExit={this.handlePlaidExit}
              onSuccess={this.handlePlaidSuccess}
            />
          </Row>
          <Row size="xsmall">
            <SecurityNotice />
          </Row>
          <Help prepend="Can’t link your bank?" />
        </Fieldset>
      </AppView>
    )
  }
}
