import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@fundrocket/common-web/components/Button'
import Modal from '@fundrocket/common-web/components/Modal'
import ModalConfirm from '@fundrocket/common-web/components/ModalConfirm'

export default class ButtonWithModal extends React.Component {
  static propTypes = {
    buttonComponent: PropTypes.func,
    buttonProps: PropTypes.object,
    className: PropTypes.string,
    handleModalButtonClick: PropTypes.func,
    modal: PropTypes.node,
    modalProps: PropTypes.object,
    modalType: PropTypes.oneOf(['confirm', 'modal']),
    showModal: PropTypes.bool,
  }

  static defaultProps = {
    buttonComponent: Button,
    modalType: 'modal',
  }

  constructor(props) {
    super(props)

    this.state = {
      showModal: props.showModal,
    }
  }

  componentWillReceiveProps(nextProps) {
    const { showModal } = nextProps

    if (showModal === this.props.showModal) return

    this.setState({ showModal })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  handleClick = () => {
    this.setState({ showModal: true })
    if (this.props.handleModalButtonClick) {
      this.props.handleModalButtonClick()
    }
  }

  render() {
    const {
      buttonComponent,
      buttonProps,
      className,
      modal,
      modalProps,
      modalType,
      showModal: propsShowModal,
      ...others
    } = this.props
    const { showModal } = this.state

    const ButtonComponent = buttonComponent
    const ModalComponent = modalType === 'confirm' ? ModalConfirm : Modal

    return (
      <>
        <ButtonComponent
          {...buttonProps}
          {...others}
          className={classNames('ButtonWithModal', className)}
          onClick={this.handleClick}
        />
        <ModalComponent {...modalProps} isOpen={showModal} onRequestClose={this.closeModal}>
          {modal &&
            React.cloneElement(modal, {
              close: this.closeModal,
            })}
        </ModalComponent>
      </>
    )
  }
}
