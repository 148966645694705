import React from 'react'

import Copy from '@fundrocket/common-web/components/Copy'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import Heading from '@fundrocket/common-web/components/Heading'
import Row from '@fundrocket/common-web/components/Row'
import Spinner from '@fundrocket/common-web/components/Spinner'
import Text from '@fundrocket/common-web/components/Text'
import notifications from '@fundrocket/common-web/libs/notifications'

import paths from 'constants/paths'
import AppView from 'components/AppView'
import Help from 'components/Help'
import InputFile from 'components/InputFile'
import urls from 'constants/urls'
import { useUserContext } from 'contexts/User'
import apiRequest from 'libs/api-request'
import AWSUploadService from 'services/AWSUploadService'

import 'react-dropzone-uploader/dist/styles.css'

const awsUploadService = new AWSUploadService()

export default function DocumentUpload() {
  const userContext = useUserContext()
  const [applicationId, setApplicationId] = React.useState('')
  const [documents, setDocuments] = React.useState([])

  React.useEffect(() => {
    getApplicationId()
    getDocuments()
  }, [])

  function getApplicationId() {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      const application = response.data[0]

      if (!application) {
        notifications.notifyAndReload({
          level: 'warning',
          message: 'You haven’t completed an application',
          to: paths.DASHBOARD,
        })
        return
      }

      setApplicationId(application.id)
    })
  }

  function getDocuments() {
    awsUploadService
      .getDocuments()
      .then((response) => setDocuments(response))
      .catch(() => {
        notifications.notify({
          level: 'error',
          message: 'Error retrieving previously uploaded documents',
        })
      })
  }

  async function getUploadParams({ meta }) {
    // TODO
    // eslint-disable-next-line no-return-await
    return await awsUploadService.getPresignedUploadParams(
      applicationId,
      meta.name,
      userContext.user.user_id
    )
  }

  function handleChangeStatus({ meta, remove }, status) {
    const { name } = meta

    if (status === 'headers_received') {
      notifications.notify({
        level: 'success',
        message: `Successfully uploaded ${name}`,
      })
      remove()
      getDocuments()
    }

    if (status === 'aborted' || status === 'error_upload' || status === 'exception_upload') {
      notifications.notify({
        level: 'error',
        message: `Error uploading ${name}`,
      })
    }
  }

  const hasDocuments = !_.isEmpty(documents)

  return (
    <AppView className="Documents">
      <Row size="small">
        <Row size="small" flush={!hasDocuments}>
          <Fieldset
            title="Documents"
            copy={
              <Text element="p" flush>
                Upload documents from your device below by clicking to locate them or use
                drag-and-drop. To upload multiple documents, upload them one-by-one.
              </Text>
            }
            standalone>
            {!applicationId ? (
              <Spinner size={50} />
            ) : (
              <InputFile uploadParams={getUploadParams} onChangeStatus={handleChangeStatus} />
            )}
          </Fieldset>
        </Row>
        {hasDocuments && (
          <>
            <Heading level="3">Uploaded documents</Heading>
            <Copy size="small">
              <ul>
                {documents.map((item) => (
                  <li key={item.key}>{item.name}</li>
                ))}
              </ul>
            </Copy>
          </>
        )}
      </Row>
      <Help prepend="Have questions?" />
    </AppView>
  )
}
