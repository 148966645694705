import React from 'react'

import BankRelinkButton from '@fundrocket/common-web/components/BankRelinkButton'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import Row from '@fundrocket/common-web/components/Row'

import AppView from 'components/AppView'
import Help from 'components/Help'
import paths from 'constants/paths'

export default function BankRelink() {
  return (
    <AppView className="BankReink">
      <Row size="small">
        <Fieldset
          title="Reconnect your bank to Womply"
          copy={
            <p>
              We lost our connection to your business checking account. Please reconnect the same
              business checking account.
            </p>
          }>
          <BankRelinkButton to={paths.DASHBOARD} />
        </Fieldset>
      </Row>
      <Help prepend="Can’t relink your bank?" />
    </AppView>
  )
}
