import Image from '@fundrocket/common-web/components/Image'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Label from '@fundrocket/common-web/components/Label'

import styles from './InputCheckbox.module.scss'
import checkmarkImage from './images/checkmark.svg'

export default function InputCheckbox({
  children,
  className,
  flush,
  highlight,
  testid,
  ...others
}) {
  return (
    <Label
      className={classNames(
        'InputCheckbox',
        styles.this,
        flush && styles['this---flush'],
        highlight && styles['this---highlight'],
        className
      )}
      testid={testid}>
      <input {...others} type="checkbox" className={styles.input} />
      <div className={styles.indicator}>
        <Image src={checkmarkImage} className={styles.checkmark} />
      </div>
      {children}
    </Label>
  )
}

InputCheckbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  flush: PropTypes.bool,
  highlight: PropTypes.bool,
  testid: PropTypes.string,
}
