import React from 'react'

import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldFirstName2(props) {
  return (
    <Field2
      autoComplete="given-name"
      label="First Name"
      //name="fname"
      name="firstName"
      placeholder="John"
      testid="first-name-input"
      // keep order
      {...props}
      className="FieldFirstName2"
    />
  )
}