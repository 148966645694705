import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@fundrocket/common-web/components/Button'

import urls from 'constants/urls'
import { useUserContext } from 'contexts/User'

export default function SignOutButton({ className, ...props }) {
  const userContext = useUserContext()

  if (!userContext.user) return null

  return (
    <Button
      {...props}
      to={urls.SIGN_OUT}
      target="_self"
      testid="sign-out-button"
      className={classNames('SignOutButton', className)}>
      Sign Out
    </Button>
  )
}

SignOutButton.propTypes = {
  className: PropTypes.string,
}
