import classNames from 'classnames'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import validations from '@fundrocket/common/libs/validations'
import Button from '@fundrocket/common-web/components/Button'
import Buttons from '@fundrocket/common-web/components/Buttons'
import FieldBankAccountNumber from '@fundrocket/common-web/components/FieldBankAccountNumber'
import FieldBankRoutingNumber from '@fundrocket/common-web/components/FieldBankRoutingNumber'
import FormValidationNotification from '@fundrocket/common-web/components/FormValidationNotification'
import notifications from '@fundrocket/common-web/libs/notifications'

import urls from 'constants/urls'
import apiRequest from 'libs/api-request'

export default function BankAccountForm({
  applicationId,
  backButton,
  className,
  isVisible,
  onSuccess,
  submitButton,
}) {
  const bankAccountNumberInput = React.useRef(null)

  // TODO: custom hook?
  React.useEffect(() => {
    if (isVisible && bankAccountNumberInput?.current) bankAccountNumberInput.current.focus()
  }, [isVisible])

  function handleSubmit(values, formikActions) {
    submit({ formikActions, values })
  }

  function submit({ formikActions, values }) {
    const { bankAccountNumber, bankRoutingNumber } = values

    apiRequest({
      url: urls.API_BANK,
      method: 'post',
      data: {
        application_id: applicationId,
        numbers: {
          account: bankAccountNumber,
          routing: bankRoutingNumber,
        },
      },
    })
      .then(() => {
        if (onSuccess) onSuccess()
      })
      .catch((error) => {
        formikActions.setSubmitting(false)

        notifications.notify({
          level: 'error',
          message: 'There was a problem with your submission. Please try again later.',
        })
        reporter.trackError(error)
      })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        bankAccountNumber: '',
        bankRoutingNumber: '',
      }}
      validationSchema={Yup.object({
        bankAccountNumber: validations.bankAccountNumber,
        bankRoutingNumber: validations.bankRoutingNumber,
      })}
      className={classNames('BankAccountForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <FieldBankAccountNumber innerRef={bankAccountNumberInput} />
          <FieldBankRoutingNumber />
          <Buttons split={!!backButton}>
            {backButton}
            {React.cloneElement(submitButton, {
              disabled: formikProps.isSubmitting,
              testid: 'bank-account-form--submit-button',
              onClick: formikProps.submitForm,
            })}
          </Buttons>
        </Form>
      )}
    </Formik>
  )
}

BankAccountForm.propTypes = {
  applicationId: PropTypes.string,
  backButton: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  onSuccess: PropTypes.func,
  submitButton: PropTypes.node,
}

BankAccountForm.defaultProps = {
  submitButton: <Button level="primary">Submit</Button>,
}
