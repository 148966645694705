import * as Yup from 'yup'

//const PLACEHOLDER_CHARACTER = '_'

const BANK_ROUTING_NUMBER_LENGTH_MAX = 9
const EIN_TIN_LENGTH = 10  // XX-XXXXXXX
const EIN_TIN_INVALID_MESSAGE = 'Invalid EIN/TIN'
const EIN_TIN_NONREDACTED_CHARS_LENGTH = 4
const EMAIL_INVALID_MESSAGE = 'Invalid email'
const FIRST_NAME_LENGTH_MAX = 50
const LAST_NAME_LENGTH_MAX = 50
const NUMBER_MESSAGE = 'Must be a number'
const NUMBER_POSITIVE_MESSAGE = 'Must be greater than 0'
const REQUIRED_MESSAGE = 'Required'
const YEAR_LENGTH = 4

const lengthMaxMessage = (length: number): string => `Must be ${length} or less characters`
const lengthMessage = (length: number): string => `Must be ${length} characters`
//const lengthMinMessage = (length: number): string => `Must be ${length} or more characters`

export default {
  required: Yup.string().required(REQUIRED_MESSAGE),

  bankAccountNumber:
    Yup.number()
      .typeError(NUMBER_MESSAGE)
      .positive(NUMBER_POSITIVE_MESSAGE)
      .required(REQUIRED_MESSAGE),
  bankRoutingNumber:
    Yup.number()
      .max(999999999, lengthMaxMessage(BANK_ROUTING_NUMBER_LENGTH_MAX))
      .typeError(NUMBER_MESSAGE)
      .positive(NUMBER_POSITIVE_MESSAGE)
      .required(REQUIRED_MESSAGE),
  // TODO
  // doesn't include PLACEHOLDER_CHARACTER
  einTin:
    Yup.string()
      //.length(EIN_TIN_LENGTH, lengthMessage(EIN_TIN_LENGTH))
      .matches(/^[^_]+$/, EIN_TIN_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
  einTinOptional:
    Yup.string()
      //.length(EIN_TIN_LENGTH, lengthMessage(EIN_TIN_LENGTH)),
      .matches(/^[^_]+$/, EIN_TIN_INVALID_MESSAGE),
  email:
    Yup.string()
      .email(EMAIL_INVALID_MESSAGE)
      .required(REQUIRED_MESSAGE),
  emailOptional:
    Yup.string()
      .email(EMAIL_INVALID_MESSAGE),
  firstName:
    Yup.string()
      .max(FIRST_NAME_LENGTH_MAX, lengthMaxMessage(FIRST_NAME_LENGTH_MAX))
      .required(REQUIRED_MESSAGE),
  lastName:
    Yup.string()
      .max(LAST_NAME_LENGTH_MAX, lengthMaxMessage(LAST_NAME_LENGTH_MAX))
      .required(REQUIRED_MESSAGE),
  registrationYear:
    Yup.string()
      .test(
        'registration-year',
        'Invalid year',
        (value) => {
          if (!value) return false
          return parseInt(value, 10) > 1776
        }
      )
      .length(YEAR_LENGTH, lengthMessage(YEAR_LENGTH))
      .required(REQUIRED_MESSAGE),

  REQUIRED_MESSAGE,

  EIN_TIN_LENGTH,
  EIN_TIN_NONREDACTED_CHARS_LENGTH,
  BANK_ROUTING_NUMBER_LENGTH_MAX,
  YEAR_LENGTH,
}