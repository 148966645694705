import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Columns.module.scss'

export default function Columns({ children, className, reset }) {
  const body = React.Children.map(children, (child, index) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      className={styles.column}>
      {child}
    </div>
  ))

  return (
    <div
      className={classNames(
        'Columns',
        styles.this,
        reset && styles[`this---reset---${reset}`],
        className
      )}>
      {body}
    </div>
  )
}

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  reset: PropTypes.oneOf(['mobile']),
}

Columns.defaultProps = {
  reset: 'mobile',
}
