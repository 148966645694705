import React from 'react'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import Text from '@fundrocket/common-web/components/Text'
import notifications from '@fundrocket/common-web/libs/notifications'

import AppView from 'components/AppView'
import BankAccountForm from 'components/BankAccountForm'
import paths from 'constants/paths'
import urls from 'constants/urls'
import apiRequest from 'libs/api-request'

export default function BankAccount() {
  const [applicationId, setApplicationId] = React.useState('')

  React.useEffect(() => {
    getApplicationId()
  }, [])

  function getApplicationId() {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      const application = response.data[0]

      if (!application) {
        notifications.notifyAndReload({
          message: 'You haven’t completed an application',
          to: paths.DASHBOARD,
          level: 'warning',
        })
        return
      }

      const { id } = application

      setApplicationId(id)
      reporter.setEventsContext({ applicationId: id })
    })
  }

  function handleSuccess() {
    notifications.notifyAndReload({
      level: 'success',
      message: 'Thanks for submitting your business account details',
      to: paths.DASHBOARD,
    })
  }

  return (
    <AppView className="BankAccount">
      <Fieldset
        title="What is your bank account information?"
        copy={
          <p>
            This is where the money is going to be deposited—please enter the business checking
            account associated with your business.{' '}
            <Text weight="semibold">
              Your setup can’t be approved if this information is not verifiable.
            </Text>
          </p>
        }
        standalone>
        <BankAccountForm applicationId={applicationId} isVisible onSuccess={handleSuccess} />
      </Fieldset>
    </AppView>
  )
}
