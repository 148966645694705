import urls from 'constants/urls'
import RestApiService from 'services/RestApiService'

const dataMap = {
  applyOffer: {
    endpoint: urls.API_APPLY,
    bodyAsForm: false,
    retry: false,
    model: (model) => model.data,
  },
  applyOfferBankUpdate: {
    endpoint: urls.API_APPLY_BANK_UPDATE,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  applyOfferTerms: {
    endpoint: urls.API_APPLY_TERMS,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  getOffer: {
    endpoint: urls.API_REDEEM,
    bodyAsForm: false,
    model: (model) => model,
  },
}

export default class OfferService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  applyOffer = (data) => {
    return this.post('applyOffer', {}, data)
  }

  applyOfferBankUpdate = (data) => {
    return this.post('applyOfferBankUpdate', {}, data)
  }

  applyOfferTerms = (data) => {
    return this.patch('applyOfferTerms', {}, data)
  }

  //getOffer = (code = '') => {
  getOffer = () => {
    return this.get('getOffer', {})
  }
}
