import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Script from 'react-load-script'

import PlaidError from '@fundrocket/common/constants/custom-errors'
import events from '@fundrocket/common/constants/events-fundrocket'
import plaid from '@fundrocket/common/constants/plaid'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Button from '@fundrocket/common-web/components/Button'
import ButtonNext from '@fundrocket/common-web/components/ButtonNext'

import Head from 'components/Head'
import urls from 'constants/urls'

export default class PlaidLink extends React.Component {
  userJourney = []

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    clientName: PropTypes.oneOf(['FundRocket', 'Womply']),
    eventPrefix: PropTypes.string,
    disabled: PropTypes.bool,
    // https://plaid.com/docs/quickstart/#api-environments
    env: PropTypes.oneOf(['development', 'production', 'sandbox']),
    institution: PropTypes.string,
    next: PropTypes.bool,
    onClick: PropTypes.func,
    onEvent: PropTypes.func,
    onExit: PropTypes.func,
    onLinkLoad: PropTypes.func,
    onLoad: PropTypes.func,
    // params: public_key, metadata object
    onSuccess: PropTypes.func.isRequired,
    // https://plaid.com/docs/quickstart/#products-overview
    product: PropTypes.arrayOf(PropTypes.oneOf(['auth', 'identity', 'income', 'transactions'])),
    selectAccount: PropTypes.bool,
    // https://plaid.com/docs/quickstart/#api-keys
    token: PropTypes.string,
    tokenRequired: PropTypes.bool,
    webhook: PropTypes.string,
  }

  static defaultProps = {
    clientName: 'Womply',
    env: process.env.GATSBY_PLAID_ENV,
    eventPrefix: '',
    institution: null,
    product: ['transactions'],
    tokenRequired: false,
  }

  state = {
    disabled: true,
  }

  componentDidMount() {
    reporter.setEventsContext({ url: window.location.toString() })
  }

  //exit = (config) => {
  //  window.linkHandler && window.linkHandler.exit(config)
  //}

  onEvent = (eventName, metadata) => {
    const { eventPrefix } = this.props
    this.userJourney.push({ eventName, metadata })

    if (eventName === 'HANDOFF') {
      this.userJourney = []
    }

    if (eventName === 'ERROR') {
      reporter.trackEvent(eventPrefix + events.PLAID_LINK_ERROR, {
        error_message: metadata.error_message,
      })
      if (!plaid.ERROR_TYPES_TO_SUPPRESS.includes(metadata.error_type)) {
        reporter.setErrorContext({
          errorInfo: { ...metadata, userJourney: this.userJourney },
        })
        reporter.trackError(
          new PlaidError(metadata.error_message, {
            eventName,
            metadata,
            userJourney: this.userJourney,
          }),
          null
        )
      } else {
        reporter.setErrorContext(
          {
            errorInfo: { ...metadata, userJourney: this.userJourney },
          },
          'low'
        )
        reporter.trackError(
          new PlaidError(metadata.error_message, {
            eventName,
            metadata,
            userJourney: this.userJourney,
          }),
          null
        )
      }
    }

    if (this.props.onEvent) {
      this.props.onEvent(eventName, metadata)
    }
  }

  onExit = (error, metadata) => {
    const { eventPrefix } = this.props
    if (error && metadata.error_message) {
      reporter.trackEvent(eventPrefix + events.PLAID_LINK_ERROR, {
        error_message: metadata.error_message,
      })
      reporter.setErrorContext({ errorInfo: { ...metadata, userJourney: this.userJourney } })
      reporter.trackError(
        new PlaidError(metadata.error_message, {
          error,
          metadata,
          userJourney: this.userJourney,
        }),
        null
      )
    }
    this.userJourney = []

    if (this.props.onExit) {
      this.props.onExit(error, metadata)
    }
  }

  handleScriptError = () => {
    const { eventPrefix } = this.props
    reporter.trackEvent(eventPrefix + events.PLAID_LINK_ERROR, {
      error_message: 'Error loading Plaid API',
    })
    reporter.trackError(new Error('Error loading Plaid API'))
  }

  handleScriptLoad = () => {
    const {
      clientName,
      env,
      eventPrefix,
      onLinkLoad,
      onSuccess,
      product,
      selectAccount,
      token,
      webhook,
    } = this.props

    window.linkHandler = window.Plaid.create({
      clientName,
      env,
      key: process.env.GATSBY_PLAID_PUBLIC_KEY,
      product,
      selectAccount,
      token,
      webhook,
      onEvent: this.onEvent,
      onExit: this.onExit,
      onLoad: onLinkLoad,
      onSuccess: (...args) => {
        reporter.trackEvent(
          token
            ? eventPrefix + events.PLAID_UPDATE_SUCCESS
            : eventPrefix + events.PLAID_LINK_SUCCESS
        )
        onSuccess(...args)
      },
    })

    this.setState({ disabled: false })
  }

  handleClick = () => {
    const { eventPrefix, institution, onClick, token } = this.props

    reporter.trackEvent(
      token ? eventPrefix + events.PLAID_UPDATE_START : eventPrefix + events.PLAID_LINK_START
    )

    if (onClick) onClick()

    if (window.linkHandler) window.linkHandler.open(institution)
  }

  render() {
    const {
      children,
      className,
      clientName,
      disabled,
      env,
      eventPrefix,
      next,
      onEvent,
      onExit,
      onLoad,
      onSuccess,
      product,
      token,
      tokenRequired,
      ...props
    } = this.props
    const { disabled: disabledState } = this.state

    const Component = next ? ButtonNext : Button

    return (
      <>
        <Head preconnect={[urls.PLAID_CDN]} />
        <Component
          {...props}
          disabled={disabledState || disabled}
          className={classNames('PlaidLink', className)}
          onClick={this.handleClick}>
          {children ? children : 'Link Account'}
        </Component>
        {((tokenRequired && token) || !tokenRequired) && (
          <Script
            url={`${urls.PLAID_CDN}/link/v2/stable/link-initialize.js`}
            onError={this.handleScriptError}
            onLoad={this.handleScriptLoad}
          />
        )}
      </>
    )
  }
}
