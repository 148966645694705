import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import urls from '@fundrocket/common/constants/urls'
import plaidItems from '@fundrocket/common/libs/plaid-items'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
//import PlaidLink from '@fundrocket/common-web/components/PlaidLink'
import PlaidLink from '@fundrocket/common-web/components/PlaidLink2'
import notifications from '@fundrocket/common-web/libs/notifications'

import apiRequest from 'libs/api-request'

export default class BankRelinkButton extends React.Component {
  state = {
    disabled: true,
    plaidItem: {},
  }

  componentDidMount() {
    reporter.setEventsContext({ url: window.location.toString() })
    this.getPlaidItemInactive()
  }

  getPlaidItemInactive = () => {
    apiRequest({
      url: urls.API_PLAID_ITEMS,
      method: 'get',
    }).then((response) => {
      const { items } = response.data

      if (!plaidItems.hasItemsInactive(items)) {
        notifications.notifyAndRedirect({
          delay: true,
          message: 'Your bank account is already connected',
          to: this.props.to,
        })
        return
      }

      // TODO: handle case where multiple items are inactive
      plaidItems.order(items)
      this.setState({
        disabled: false,
        plaidItem: plaidItems.getItemInactive(items),
      })
    })
  }

  handlePlaidExit = (error, metadata) => {
    if (metadata.status === 'institution_not_found') {
      notifications.notify({
        message: 'Your account is locked! Visit your bank’s site and unlock your account.',
        level: 'warning',
      })
    }

    if (error && error.error_code === 'ITEM_LOCKED' && error.error_type === 'ITEM_ERROR') {
      notifications.notify({
        message: 'Your account is locked! Visit your bank’s site and unlock your account.',
        level: 'warning',
      })
    }
  }

  handlePlaidSuccess = () => {
    this.updatePlaidItem()
    this.setState({ disabled: true })
  }

  updatePlaidItem = () => {
    apiRequest({
      url: urls.API_PLAID_ITEMS_RECONNECT,
      method: 'patch',
      data: {
        item_id: this.state.plaidItem.item_id,
      },
    }).then((response) => {
      if (_.get(response, 'status') !== 204) {
        this.setState({ disabled: false })
        return
      }

      notifications.notifyAndReload({
        message: 'Thank you for reconnecting your bank account!',
        to: this.props.to,
        level: 'success',
      })
    })
  }

  render() {
    const { to, ...props } = this.props
    const { disabled, plaidItem } = this.state

    if (_.isEmpty(plaidItem)) return null

    return (
      <PlaidLink
        {...props}
        disabled={disabled}
        itemId={plaidItem.item_id}
        level="primary"
        onExit={this.handlePlaidExit}
        onSuccess={this.handlePlaidSuccess}
      />
    )
  }
}

BankRelinkButton.propTypes = {
  to: PropTypes.string,
}
