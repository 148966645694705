import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import TextMask from 'react-text-mask'

import cursorMaskHandler from '@fundrocket/common-web/libs/cursor-mask-handler'

import styles from './InputEinTin.module.scss'

export default function InputEinTin({ className, ...others }) {
  return (
    <TextMask
      {...others}
      mask={[/[\d•]/, /[\d•]/, '-', /[\d•]/, /[\d•]/, /[\d•]/, /[\d•]/, /[\d•]/, /[\d•]/, /[\d•]/]}
      placeholder="12-3456789"
      type="tel"
      className={classNames('InputEinTin', styles.this, className)}
      onClick={cursorMaskHandler('-')}
    />
  )
}

InputEinTin.propTypes = {
  className: PropTypes.string,
}
