import PropTypes from 'prop-types'
import React from 'react'

import Button from '@fundrocket/common-web/components/Button'

import { ReactComponent as ArrowRightSvg } from './images/arrow---right.svg'

export default function ButtonNext({ children, ...others }) {
  return (
    <Button {...others} icon={<ArrowRightSvg />} iconAppend level="primary">
      {children}
    </Button>
  )
}

ButtonNext.propTypes = {
  children: PropTypes.node,
}

ButtonNext.defaultProps = {
  children: 'Next',
}
