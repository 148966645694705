import PropTypes from 'prop-types'
import React from 'react'

import copy from '@fundrocket/common/constants/copy'
import urls from '@fundrocket/common/constants/urls'
import Text from '@fundrocket/common-web/components/Text'

//import Link from 'components/Link'

export default function EinTinNote({ className, ...props }) {
  return (
    <Text {...props}>
      {copy.EIN_TIN_TOOLTIP}
      {/*
        <br />
        <Link to={urls.IRS_EIN} weight="semibold">
          Learn more
        </Link>
      */}
    </Text>
  )
}

EinTinNote.propTypes = {
  className: PropTypes.string,
}
