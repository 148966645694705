import _ from 'lodash'

const COLOR_PARAM = 'color: #1867d1'

function toConsole(value) {
  if (process.env.GATSBY_FR_RELEASE_LEVEL === 'prod') return

  if (_.isObject(value) && !_.isUndefined(value.value)) {
    // eslint-disable no-console
    console.groupCollapsed(`%c${value.message}`, COLOR_PARAM)
    console.log(`%c${JSON.stringify(value.value, null, 2)}`, COLOR_PARAM)
    console.groupEnd()
  } else {
    console.log(`%c${value.message || value}`, COLOR_PARAM)
  }
}

export default {
  toConsole,
}
