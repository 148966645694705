import { navigate } from 'gatsby'
import qs from 'qs'
import React from 'react'

import plaidItems from '@fundrocket/common/libs/plaid-items'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import BankRelinkButton from '@fundrocket/common-web/components/BankRelinkButton'
import Button from '@fundrocket/common-web/components/Button'
import DateTime from '@fundrocket/common-web/components/DateTime'
import Heading from '@fundrocket/common-web/components/Heading'
import Notice from '@fundrocket/common-web/components/Notice'
import Row from '@fundrocket/common-web/components/Row'
import Spinner from '@fundrocket/common-web/components/Spinner'
import Text from '@fundrocket/common-web/components/Text'

import AppView from 'components/AppView'
import Help from 'components/Help'
import applications from 'constants/applications'
import paths from 'constants/paths'
import urls from 'constants/urls'
import apiRequest from 'libs/api-request'
import logging from 'libs/logging'
import pay from 'libs/pay'

const USER_STATUS_NO_ACTION = 'no-action'

const USER_STATUSES = {
  [USER_STATUS_NO_ACTION]: 'You’re all set—there’s nothing to do.',
}

export default class Dashboard extends React.Component {
  state = {
    application: null,
    applicationStatus: null,
    applicationSubmissionStatus: null,
    institutionName: null,
    needsBankRelink: null,
    needsBusinessTaxId: null,
    plaidItemDateCreated: null,
    product: 'Womply',
    status: null,
  }

  queryString = {
    blid: '',
    email: '',
    phone: '',
  }

  componentDidMount() {
    this.queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    this.getApplications()
    this.getBankLinkStatus()
  }

  getApplications = () => {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      logging.toConsole({
        message: 'getApplications',
        value: response,
      })
      const application = response.data[0]

      if (!application) {
        this.goToSetupApplication()
        return
      }

      const {
        //business_address: businessAddress,
        business_name: businessName,
        business_tax_id: businessTaxId,
        campaign_id: campaignId,
        first_name: firstName,
        has_plaid_item_id: hasBank,
        id: applicationId,
        status: applicationSubmissionStatus,
      } = application

      reporter.setEventsContext({ applicationId })

      this.setState({
        application,
        applicationSubmissionStatus,
      })

      if (campaignId === pay.CAMPAIGN_ID) {
        this.setState({ product: 'Womply Pay' })
      }

      if (applications.SUBMISSION_STATUSES_TERMINAL.includes(applicationSubmissionStatus)) {
        this.setState({ status: USER_STATUS_NO_ACTION })
        return
      }

      let applicationStatus

      if (applicationSubmissionStatus !== applications.SUBMISSION_STATUS_REQUESTED) {
        applicationStatus = applications.STATUS_SUMMARY

        if (!hasBank) applicationStatus = applications.STATUS_BANK_AUTH
        if (!businessName) applicationStatus = applications.STATUS_BUSINESS
        if (!firstName) applicationStatus = applications.STATUS_PERSONAL

        this.setState({ applicationStatus })
        return
      }

      if (!businessTaxId) {
        this.setState({
          needsBusinessTaxId: true,
          status: 'need',
        })
        //} else if (!businessAddress) {
        //  this.setState({
        //    needsBusinessAddress: true,
        //    status: 'need',
        //  })
      } else {
        this.setState({ status: USER_STATUS_NO_ACTION })
      }
    })
  }

  getBankLinkStatus = () => {
    apiRequest({
      url: urls.API_PLAID_ITEMS,
      method: 'get',
    }).then((response) => {
      logging.toConsole({
        message: 'getBankLinkStatus',
        value: response,
      })
      const { institutions, items } = response.data

      if (!plaidItems.hasItemsInactive(items)) return

      const plaidItemInactive = plaidItems.getItemInactive(items)
      const institution = plaidItems.getInstitutionById(
        institutions,
        plaidItemInactive.institution_id
      )

      this.setState({
        institutionName: institution.name,
        needsBankRelink: true,
        plaidItemDateCreated: plaidItemInactive.created,
      })
    })
  }

  goToStartedSetupApplication = () => {
    logging.toConsole('goToStartedSetupApplication')
    const { application, applicationStatus } = this.state
    const { id: applicationId, reference_id: applicationReferenceId } = application
    const { blid, email, phone } = this.queryString

    navigate(
      `${paths.SETUP}?${qs.stringify({
        applicationId,
        applicationReferenceId,
        blid,
        email,
        phone,
        status: applicationStatus,
      })}`
    )
  }

  goToSetupApplication = () => {
    logging.toConsole('goToSetupApplication')
    const { blid, email, phone } = this.queryString

    navigate(
      `${paths.SETUP}?${qs.stringify({
        blid,
        email,
        phone,
      })}`
    )
  }

  handleContinueSetupApplication = () => {
    logging.toConsole('handleContinueSetupApplication')
    this.goToStartedSetupApplication()
  }

  renderBody() {
    const {
      applicationStatus,
      applicationSubmissionStatus,
      institutionName,
      needsBankRelink,
      needsBusinessTaxId,
      plaidItemDateCreated,
      product,
      status,
    } = this.state

    if (!applicationStatus && !status) {
      return <Spinner size={50} />
    }

    const title = (
      <Heading level="1" levelStyle="2">
        {product} business setup status
      </Heading>
    )

    if (applicationSubmissionStatus === applications.SUBMISSION_STATUS_APPROVED) {
      return (
        <>
          {title}
          <Row size="small">
            <Notice>
              <Text weight="semibold">Congratulations</Text>—your business is setup!
            </Notice>
          </Row>
          <Button to={urls.WOMPLY_DASHBOARD} level="primary">
            Go to Womply Dashboard
          </Button>
        </>
      )
    }

    if (
      applicationSubmissionStatus === applications.SUBMISSION_STATUS_CANCELLED ||
      applicationSubmissionStatus === applications.SUBMISSION_STATUS_DECLINED
    ) {
      return (
        <>
          {title}
          <Row size="small">
            <Notice>Unfortunately we weren’t able to setup your business.</Notice>
          </Row>
          <Help prepend="Have questions?" />
        </>
      )
    }

    if (
      applicationSubmissionStatus === applications.SUBMISSION_STATUS_STARTED &&
      applications.STATUSES.includes(applicationStatus)
    ) {
      let suffix = 'you to agree to the terms'

      if (applicationStatus === applications.STATUS_BANK_AUTH) suffix = 'you to link your bank'
      if (applicationStatus === applications.STATUS_BUSINESS) suffix = 'more information'
      if (applicationStatus === applications.STATUS_PERSONAL) suffix = 'information about you'

      return (
        <>
          {title}
          <Row size="small">
            <Notice>
              <Text weight="semibold">You haven’t finished setting up your business</Text>—we need{' '}
              {suffix} to proceed.
            </Notice>
          </Row>
          <Button
            level="primary"
            onClick={this.handleContinueSetupApplication}
            testid="dashboard-form--finish-setup">
            Finish Setup
          </Button>
        </>
      )
    }

    if (
      applicationSubmissionStatus !== applications.SUBMISSION_STATUS_STARTED &&
      applications.STATUSES.includes(applicationStatus)
    ) {
      return (
        <>
          {title}
          <Row size="small">
            <Notice>
              <Text weight="semibold">Congratulations</Text>—you’ve submitted your business setup!
            </Notice>
          </Row>
          <Button to={urls.WOMPLY_DASHBOARD} level="primary">
            Go to Womply Dashboard
          </Button>
        </>
      )
    }

    if (needsBankRelink) {
      return (
        <>
          <Heading>Status</Heading>
          <Row size="small">
            <Notice>
              We <Text weight="semibold">lost connection to your account(s)</Text> at{' '}
              {institutionName} that you linked on{' '}
              <DateTime
                // TODO: should be able to parse timestamp
                date={new Date(plaidItemDateCreated)}
              />
              .
            </Notice>
          </Row>
          <BankRelinkButton />
        </>
      )
    }

    if (needsBusinessTaxId) {
      return (
        <>
          {title}
          <Row size="small">
            <Notice>
              We need your <Text weight="semibold">business tax ID</Text> to finish your
              application.
            </Notice>
          </Row>
          <Button to={paths.BUSINESS_TAX_ID} level="primary">
            Finish Setup
          </Button>
        </>
      )
    }

    return (
      <>
        {title}
        <Notice>{USER_STATUSES[status]}</Notice>
      </>
    )
  }

  render() {
    return <AppView className="Dashboard">{this.renderBody()}</AppView>
  }
}
