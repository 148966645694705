import React from 'react'

import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldBusinessName2(props) {
  return (
    <Field2
      label="Legal Business Name"
      //name="organization"
      name="businessName"
      placeholder="John Smith LLC"
      testid="business-name-input"
      // keep order
      {...props}
      className="FieldBusinessName2"
    />
  )
}