import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Container from '@fundrocket/common-web/components/Container'

import Link from 'components/Link'
import SignOutButton from 'components/SignOutButton'
import paths from 'constants/paths'

import { ReactComponent as LogoSvg } from './images/logo.svg'
import styles from './Header.module.scss'

export default function Header({ className }) {
  return (
    <header className={classNames('Header', styles.this, className)}>
      <Container className={styles.container}>
        <Link to={paths.HOME} decorated={false} className={styles.logo}>
          <LogoSvg aria-label="Womply" />
        </Link>
        <SignOutButton size="small" className={styles['sign-out-btn']} />
      </Container>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}
