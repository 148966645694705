import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Image from '@fundrocket/common-web/components/Image'
import MediaObject from '@fundrocket/common-web/components/MediaObject'

import styles from './InputRadiosBars.module.scss'
import checkmarkImage from './images/checkmark.svg'

export default class InputRadiosBars extends React.Component {
  static propTypes = {
    checked: PropTypes.string,
    className: PropTypes.string,
    inputs: PropTypes.array.isRequired,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      checked: props.checked || props.inputs[0].value,
    }
  }

  componentDidMount() {
    const { onChange } = this.props

    if (onChange) onChange(this.state.checked)
  }

  handleChange = (value) => {
    const { onChange } = this.props

    this.setState({ checked: value })

    if (onChange) onChange(value)
  }

  render() {
    const { className, inputs } = this.props
    const { checked } = this.state

    return (
      <div className={classNames('InputRadios', className)}>
        {inputs.map((input) => {
          const value = input.value || input.option

          return (
            // eslint-disable-next-line
            <div
              key={value}
              data-value={value}
              className={classNames(styles.item, checked === value && styles['item---active'])}
              onClick={() => this.handleChange(value)}>
              <MediaObject center>
                <div className={styles['item--indicator']}>
                  <Image src={checkmarkImage} />
                </div>
                {input.body}
              </MediaObject>
            </div>
          )
        })}
      </div>
    )
  }
}
