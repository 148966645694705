import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Buttons.module.scss'

export default function Buttons({
  children,
  align = null,
  axis = 'x',
  className = null,
  reset = null,
  split = null,
  ...others
}) {
  const wrappedChildren = React.Children.map(children, (child, index) => {
    if (child == null) {
      return null
    }

    const childWithProps = React.cloneElement(child, { ...others })

    return (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={styles.item}>
        {childWithProps}
      </div>
    )
  })

  return (
    <div
      className={classNames(
        'Buttons',
        styles.this,
        align && styles[`this---${align}`],
        axis && styles[`this---${axis}`],
        reset && styles[`this---reset---${reset}`],
        split && children.length > 1 && styles['this---split'],
        className
      )}>
      {wrappedChildren}
    </div>
  )
}

Buttons.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  axis: PropTypes.oneOf(['x', 'y']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  reset: PropTypes.oneOf(['mobile', 'mobile---m']),
  split: PropTypes.bool,
}
