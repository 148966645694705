// TODO rename to setup

import urls from 'constants/urls'

const STATUS_OFFER_SELECTION = 'offer'
const STATUS_PERSONAL = 'personal'
const STATUS_CONTACT = 'contact'
const STATUS_BUSINESS = 'business'
const STATUS_INFORMATION = 'information'
const STATUS_VERIFICATION = 'verification'
const STATUS_PHONE_VERIFICATION = 'phone-verification'
const STATUS_BANK_AUTH = 'bank'
const STATUS_BANK_ACCOUNTS = 'bank-accounts'
const STATUS_BANK_MANUAL = 'bank-manual'
const STATUS_REQUESTED = 'requested'
const STATUS_SUMMARY = 'summary'
const STATUS_SUBMITTED = 'submitted'

const SUBMISSION_STATUS_STARTED = 'STARTED'
const SUBMISSION_STATUS_REQUESTED = 'REQUESTED'
const SUBMISSION_STATUS_APPROVED = 'APPROVED'
const SUBMISSION_STATUS_CANCELLED = 'CANCELLED'
const SUBMISSION_STATUS_DECLINED = 'DECLINED'

export default {
  preconnectUrls: [urls.PLAID_CDN],

  STATUS_OFFER_SELECTION,
  STATUS_PERSONAL,
  STATUS_CONTACT,
  STATUS_BUSINESS,
  STATUS_INFORMATION,
  STATUS_VERIFICATION,
  STATUS_PHONE_VERIFICATION,
  STATUS_BANK_AUTH,
  STATUS_BANK_ACCOUNTS,
  STATUS_BANK_MANUAL,
  STATUS_REQUESTED,
  STATUS_SUMMARY,
  STATUS_SUBMITTED,

  STATUSES: [
    STATUS_OFFER_SELECTION,
    STATUS_PERSONAL,
    STATUS_CONTACT,
    STATUS_BUSINESS,
    STATUS_INFORMATION,
    STATUS_VERIFICATION,
    STATUS_PHONE_VERIFICATION,
    STATUS_BANK_AUTH,
    STATUS_BANK_ACCOUNTS,
    STATUS_BANK_MANUAL,
    STATUS_REQUESTED,
    STATUS_SUMMARY,
    STATUS_SUBMITTED,
  ],

  SUBMISSION_STATUS_STARTED,
  SUBMISSION_STATUS_REQUESTED,
  SUBMISSION_STATUS_APPROVED,
  SUBMISSION_STATUS_DECLINED,

  SUBMISSION_STATUSES_TERMINAL: [
    SUBMISSION_STATUS_APPROVED,
    SUBMISSION_STATUS_CANCELLED,
    SUBMISSION_STATUS_DECLINED,
  ],
}
