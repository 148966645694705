import _ from 'lodash'

function getBusinessCheckingAccount(accounts) {
  return _.find(accounts, (item) => item.name.toLowerCase().includes('business'))
}

function getCheckingAccount(accounts) {
  return _.find(accounts, (item) => item.subtype === 'checking')
}

function getCheckingAccounts(accounts) {
  return _.filter(accounts, (item) => item.subtype === 'checking')
}

function getAccount(accounts) {
  const businessCheckingAccount = getBusinessCheckingAccount(accounts)

  if (businessCheckingAccount) return businessCheckingAccount

  const checkingAccount = getCheckingAccount(accounts)

  if (checkingAccount) return checkingAccount

  return accounts[0]
}

function getAccounts(accounts) {
  const checkingAccounts = getCheckingAccounts(accounts)

  if (hasAccounts(checkingAccounts)) return checkingAccounts

  return accounts
}

function hasAccounts(accounts) {
  return !_.isEmpty(accounts)
}

export default {
  getAccount,
  getAccounts,
  getCheckingAccounts,
  hasAccounts,
}
