const display = (number: number): string => {
  if (!number) return ''

  return parseInt(String(number), 10).toLocaleString()
}

const currencyWithCents = (number: number | string): string => {
  if (!number) return ''
  return `$${parseFloat(String(number)).toFixed(2)}`
}

const currency = (number: number) => {
  if (!number) return ''

  return `$${display(number)}`
}

const currencyShort = (number: number): string => {
  if (!number) return ''

  if (number < 1000) return currency(number)

  return `$${(number / 1000).toFixed(number % 1000 === 0 ? 0 : 1)}K`
}

const percentage = (number: number): string => {
  if (!number) return ''

  // TODO
  return `${Number(number)
    .toFixed(1)
    .replace('.0', '')}%`
}

export default {
  currency,
  currencyShort,
  currencyWithCents,
  display,
  percentage,
}
