import { navigate } from 'gatsby'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'

import verifyPhone from '@fundrocket/common/libs/verify-phone'
import Route from '@fundrocket/common-web/components/Route'

import paths from 'constants/paths'
import { UserContext } from 'contexts/User'

export default class RoutePrivate extends React.Component {
  static contextType = UserContext

  queryString = (typeof window !== 'undefined' && window.location.search) || ''

  componentDidMount() {
    this.getAuthStatus()
  }

  getAuthStatus = async () => {
    const { fullPath, path } = this.props

    const verifyStatus = await verifyPhone.status()

    if (verifyStatus) {
      this.context.updateUser({ user: verifyStatus })
    } else {
      navigate(
        `${paths.SIGN_IN}?${qs.stringify({ redirect: `${fullPath || path}${this.queryString}` })}`
      )
    }
  }

  render() {
    const { fullPath, path, ...props } = this.props

    if (!get(this, 'context.user')) return null

    return <Route {...props} />
  }
}

RoutePrivate.propTypes = {
  component: PropTypes.any.isRequired,
  fullPath: PropTypes.string,
  path: PropTypes.string.isRequired,
}
