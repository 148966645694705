import Cookies from 'js-cookie'

import headers from 'constants/headers'
import request from 'libs/request'

export default function apiRequest({ data = {}, method, retry = true, url }) {
  headers['X-CSRFToken'] = Cookies.get('csrftoken') || null

  const defaultArguments = {
    url,
    method,
    headers,
    withCredentials: true,
    data,
  }

  const noRetryArgument = {
    raxConfig: {
      retry: 0,
      shouldRetry: false,
    },
  }

  const args = retry
    ? defaultArguments
    : {
        ...defaultArguments,
        ...noRetryArgument,
      }

  return request(args)
}
