import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import React from 'react'

import urls from '@fundrocket/common/constants/urls'
import PlaidLink from '@fundrocket/common-web/components/PlaidLink'
import paths from '@fundrocket/common-web/constants/paths'

import apiRequest from 'libs/api-request'
//import PlaidLink2 from './PlaidLink2'

export default function PlaidLink2Container({ clientName, itemId, ...props }) {
  const location = useLocation()
  const [token, setToken] = React.useState('')

  React.useEffect(() => {
    apiRequest({
      url: urls.API_PLAID_LINK_TOKEN,
      method: itemId ? 'put' : 'post',
      data: {
        client_name: clientName,
        id: itemId,
        redirect_uri: `${location.origin}${paths.PLAID_REDIRECT}`,
      },
    }).then((response) => {
      if (token) return
      const { link_token: linkToken } = response.data
      setToken(linkToken)
      //Cookies.set(plaid.LINK_TOKEN_KEY, linkToken)
    }).catch((error) => {
      console.error(error)
    })
  }, [])

  if (!token) return null

  return <PlaidLink {...props} clientName={clientName} token={token} />
}

PlaidLink2Container.propTypes = {
  clientName: PropTypes.string,
  itemId: PropTypes.string,
}

PlaidLink2Container.defaultProps = {
  clientName: 'Womply',
}
