import React from 'react'

import validations from '@fundrocket/common/libs/validations'
import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldBankRoutingNumber(props) {
  return (
    <Field2
      inputMode="numeric"
      label="Bank Routing Number"
      maxLength={validations.BANK_ROUTING_NUMBER_LENGTH_MAX}
      name="bankRoutingNumber"
      placeholder="123456789"
      type="tel"
      testid="bank-routing-number-input"
      // keep order
      {...props}
      className="FieldBankRoutingNumber"
    />
  )
}