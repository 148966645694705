import classNames from 'classnames'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import Button from '@fundrocket/common-web/components/Button'
import Buttons from '@fundrocket/common-web/components/Buttons'
import Copy from '@fundrocket/common-web/components/Copy'
import Heading from '@fundrocket/common-web/components/Heading'
import Modal from '@fundrocket/common-web/components/Modal'
import Row from '@fundrocket/common-web/components/Row'
import Text from '@fundrocket/common-web/components/Text'
import Title from '@fundrocket/common-web/components/Title'

import styles from './ModalConfirm.module.scss'

function handleConfirm(onConfirm, onRequestClose) {
  onConfirm()
  onRequestClose()
}

export default function ModalConfirm({
  children = null,
  onConfirm = null,
  onRequestClose = null,
  isOpen = false,
  cancelText = 'Cancel',
  className = null,
  confirmButtonProps = null,
  confirmText = 'Okay',
  disableConfirm = null,
  hideCancel = null,
  size = 'small',
  small = null,
  title = 'Are you sure?',
  ...props
}) {
  return (
    <Modal
      {...props}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      size={size}
      className={classNames('ModalConfirm', className)}
      onRequestClose={onRequestClose}>
      <Text align="center" element="div" className={styles.this}>
        {small ? <Title small={small}>{title}</Title> : <Heading>{title}</Heading>}

        {children && (
          <Row>
            <Copy>{children}</Copy>
          </Row>
        )}
        <Buttons align="center">
          {!hideCancel && (
            <Button color="white" level="secondary" onClick={onRequestClose}>
              {cancelText}
            </Button>
          )}
          <Button
            {...confirmButtonProps}
            disabled={disableConfirm || get(confirmButtonProps, 'disabled')}
            level="primary"
            onClick={() => handleConfirm(onConfirm, onRequestClose)}>
            {get(confirmButtonProps, 'children') || confirmText}
          </Button>
        </Buttons>
      </Text>
    </Modal>
  )
}

ModalConfirm.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  confirmButtonProps: PropTypes.object,
  confirmText: PropTypes.string,
  disableConfirm: PropTypes.bool,
  hideCancel: PropTypes.bool,
  image: PropTypes.string,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  small: PropTypes.node,
  title: PropTypes.string,
}
