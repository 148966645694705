import classNames from 'classnames'
import { times } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import formatNumber from '@fundrocket/common/libs/format-number'
import Display from '@fundrocket/common-web/components/Display'
import Text from '@fundrocket/common-web/components/Text'

import styles from './Progress.module.scss'

export default function Progress({ amount, amountUpTo, axis, className, currentStep, steps }) {
  // one-base values
  const stepsCount = steps.length - 1
  let dotIndex = -1

  return (
    <div
      className={classNames(
        'Progress',
        styles.this,
        !amount && styles['this---no-amount'],
        axis && styles[`this---${axis}`],
        className
      )}>
      <div className={styles['bar-dots']}>
        <div
          style={{
            [axis === 'y' ? 'height' : 'width']: `${100 - (currentStep / stepsCount) * 100}%`,
          }}
          className={styles.mask}
        />
        {times(stepsCount + 1, (step) => {
          dotIndex++

          const positionRounded = Math.round((dotIndex / stepsCount) * 100)

          return (
            <div
              style={{ [axis === 'y' ? 'top' : 'left']: `${(100 / stepsCount) * dotIndex}%` }}
              key={step}
              className={classNames(
                styles.dot,
                `dot---${positionRounded}`,
                dotIndex < currentStep && `dot---${positionRounded}---active`,
                dotIndex === currentStep && styles['dot---active']
              )}>
              {axis === 'y' && (
                <Text
                  color="grey"
                  noWrap
                  weight="semibold"
                  className={classNames(
                    styles['dot--label'],
                    dotIndex === currentStep && styles['dot--label---active']
                  )}>
                  {steps[dotIndex]}
                </Text>
              )}
            </div>
          )
        })}
      </div>
      {axis === 'x' && (
        <div
          className={classNames(
            styles.amount,
            !amount && styles['amount---empty'],
            currentStep === stepsCount && styles['amount---active']
          )}>
          <Display hide="mobile">
            <Text noWrap>
              {amountUpTo
                ? `Up to ${formatNumber.currencyShort(amount)}`
                : formatNumber.currency(amount)}
            </Text>
          </Display>
          <Display show="mobile">$</Display>
        </div>
      )}
    </div>
  )
}

Progress.propTypes = {
  amount: PropTypes.number,
  amountUpTo: PropTypes.bool,
  axis: PropTypes.oneOf(['x', 'y']),
  className: PropTypes.string,
  currentStep: PropTypes.number,
  steps: PropTypes.array.isRequired,
}

Progress.defaultProps = {
  axis: 'y',
  currentStep: 1,
}
