import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import Copy from '@fundrocket/common-web/components/Copy'
import Heading from '@fundrocket/common-web/components/Heading'
import Image from '@fundrocket/common-web/components/Image'
import Text from '@fundrocket/common-web/components/Text'

import styles from './Module3.module.scss'

export default function Module3({ button, children, className, image, size, title, ...props }) {
  return (
    <Text {...props} element="div" className={classNames('Module3', className)}>
      {image && <Image src={image} className={styles.image} />}
      <Heading level={size === 'small' ? '4' : '3'}>{title}</Heading>
      {_.isString(children) ? <p>{children}</p> : <Copy>{children}</Copy>}
      {button && <footer className={styles.footer}>{button}</footer>}
    </Text>
  )
}

Module3.propTypes = {
  button: PropTypes.node,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  title: PropTypes.string.isRequired,
}

Module3.defaultProps = {
  size: 'medium',
}
