import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'

import Button from '@fundrocket/common-web/components/Button'
import Container from '@fundrocket/common-web/components/Container'

import { ReactComponent as CloseSvg } from './images/x.svg'
import './Modal.scss'

export default function Modal({
  children,
  className,
  onRequestClose,
  size,
  isOpen = false,
  ...others
}) {
  return (
    <ReactModal
      {...others}
      isOpen={isOpen}
      ariaHideApp={false}
      closeTimeoutMS={225}
      onRequestClose={onRequestClose}
      className={classNames('Modal', size && `Modal---${size}`, className)}>
      <Button
        color="white"
        level="icon"
        aria-label="Close modal"
        className="Modal--close-button"
        onClick={onRequestClose}>
        <CloseSvg />
      </Button>
      {/* TODO: use max-width not Container */}
      <Container size={size === 'large' ? null : size}>{children}</Container>
    </ReactModal>
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  isOpen: PropTypes.bool,
}

Modal.defaultProps = {
  size: 'medium',
}
