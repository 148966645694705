import React from 'react'

import notifications from '@fundrocket/common-web/libs/notifications'

import AppView from 'components/AppView'
// TODO
import EinTinForm from 'components/EinTinForm'
import paths from 'constants/paths'

export default class BusinessTaxId extends React.Component {
  handleSuccess = () => {
    notifications.notifyAndReload({
      level: 'success',
      message: 'Thanks for submitting your business tax ID',
      to: paths.DASHBOARD,
    })
  }

  render() {
    return (
      <AppView className="BusinessTaxId">
        <EinTinForm onSuccess={this.handleSuccess} />
      </AppView>
    )
  }
}
