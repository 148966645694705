import PropTypes from 'prop-types'
import React from 'react'
import ReactDropzone from 'react-dropzone-uploader'

const SIZE_64_MB = 1024 * 1024 * 64

export default function InputFile({ uploadParams, ...props }) {
  return (
    <ReactDropzone
      {...props}
      canCancel={false}
      getUploadParams={uploadParams}
      inputContent="Click or drop documents to upload"
      maxFiles={1}
      maxSizeBytes={SIZE_64_MB}
      multiple={false}
      styles={{
        dropzone: {
          // `$input-border-color---focus`
          //borderColor: '#247fff',
          borderColor: '#0805c4',
          overflow: 'hidden',
        },
        inputLabel: {
          // TODO
          color: '#0805c4',
        },
      }}
    />
  )
}

InputFile.propTypes = {
  onChangeStatus: PropTypes.func.isRequired,
  uploadParams: PropTypes.object.isRequired,
}
