function toTop() {
  try {
    window.scroll({
      behavior: 'smooth',
      top: 0,
    })
  } catch (error) {
    if (error instanceof TypeError) {
      window.scroll(0, 0)
    } else {
      throw error
    }
  }
}

export default {
  toTop,
}
