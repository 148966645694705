import React from 'react'

import validations from '@fundrocket/common/libs/validations'
import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldYear2(props) {
  return (
    <Field2
      maxLength={validations.YEAR_LENGTH}
      placeholder="YYYY"
      // keep order
      {...props}
      className="FieldYear2"
    />
  )
}