import React from 'react'

import Heading from '@fundrocket/common-web/components/Heading'
import MediaObject from '@fundrocket/common-web/components/MediaObject'
import Notice from '@fundrocket/common-web/components/Notice'
import Text from '@fundrocket/common-web/components/Text'

import { ReactComponent as LockSvg } from './images/lock.svg'

export default function SecurityNotice() {
  return (
    <Notice color="grey">
      <MediaObject>
        <LockSvg />
        <section>
          <Heading level="3" levelStyle="5">
            Security by Visa
          </Heading>
          <Text element="p" flush size="small">
            Womply uses the best available security to connect to your bank. We do not share your
            data with other companies.
          </Text>
        </section>
      </MediaObject>
    </Notice>
  )
}
