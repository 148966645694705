import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Heading from '@fundrocket/common-web/components/Heading'
import Text from '@fundrocket/common-web/components/Text'

import styles from './Title.module.scss'

export default function Title({ align, children, className, flush, size, small }) {
  return (
    <header
      className={classNames(
        'Title',
        styles.this,
        align && styles[`this---${align}`],
        flush && styles['this---flush'],
        className
      )}>
      <Heading
        level={size === 'large' ? '1' : '2'}
        className={size === 'medium' ? styles.title : null}>
        {children}
      </Heading>
      <Text element="p" size="large" weight="semibold">
        {small}
      </Text>
    </header>
  )
}

Title.propTypes = {
  align: PropTypes.oneOf(['left', 'center']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flush: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'medium']),
  small: PropTypes.node.isRequired,
}

Title.defaultProps = {
  size: 'medium',
}
