import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import Markdown from 'react-markdown'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Button from '@fundrocket/common-web/components/Button'
import ButtonBack from '@fundrocket/common-web/components/ButtonBack'
import ButtonNext from '@fundrocket/common-web/components/ButtonNext'
import Buttons from '@fundrocket/common-web/components/Buttons'
import Copy from '@fundrocket/common-web/components/Copy'
import Field from '@fundrocket/common-web/components/Field'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import Heading from '@fundrocket/common-web/components/Heading'
import InputCheckbox from '@fundrocket/common-web/components/InputCheckbox'
import Row from '@fundrocket/common-web/components/Row'
import Spinner from '@fundrocket/common-web/components/Spinner'
import Text from '@fundrocket/common-web/components/Text'
import notifications from '@fundrocket/common-web/libs/notifications'

import Link from 'components/Link'
import urls from 'constants/urls'
import apiRequest from 'libs/api-request'
import pay from 'libs/pay'
import FundingSummaryService from 'services/FundingSummaryService'
import OfferService from 'services/OfferService'

import styles from './TermsForm.module.scss'

const fundingSummaryService = new FundingSummaryService()
const offerService = new OfferService()

export default function TermsForm({ onSuccess }) {
  const [application, setApplication] = React.useState({})
  const [agreement, setAgreement] = React.useState(false)
  const [terms, setTerms] = React.useState('')
  const [title, setTitle] = React.useState('Womply business setup terms')

  React.useEffect(() => {
    getApplications()

    if (pay.isPay(window.location)) {
      setTitle('Womply Pay business setup terms')
    }
  }, [])

  React.useEffect(() => {
    const {
      businessName,
      firstName,
      lastName,
    } = application

    fundingSummaryService
      .getFundingSummaryBusinessSetupTerms()
      .then((data) => {
        const termsReplaced = data
          .replace(/{businessName}/g, businessName)
          .replace(/{firstName}/g, firstName)
          .replace(/{lastName}/g, lastName)

        setTerms(termsReplaced)
      })
      .catch((error) => {
        reporter.trackError(error)
      })
  }, [application])

  function getApplications() {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      const firstApplication = response.data[0]

      if (!firstApplication) {
        navigate(paths.DASHBOARD)
        return
      }

      setApplication({
        businessName: firstApplication.business_name,
        firstName: firstApplication.first_name,
        id: firstApplication.id,
        lastName: firstApplication.last_name,
      })
    })
  }

  function handleAgreementChange() {
    setAgreement(!agreement)
  }

  function handleSubmit() {
    submit()
  }

  function submit() {
    const { id: applicationId } = application

    offerService
      .applyOfferTerms({
        application_id: applicationId,
        funding_summary: terms,
      })
      .then(() => {
        if (onSuccess) onSuccess()
      })
      .catch((error) => {
        notifications.notify({
          level: 'error',
          message: 'There was a problem with your submission. Please try again later.',
        })

        reporter.trackError(error)
      })
  }

  function LinkRenderer({ children, href }) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={href} target="_blank">
        {children}
      </a>
    )
  }

  return (
    <form className="TermsForm">
      <Fieldset
        title={title}
        copy={
          <p>
            We need you to agree to these updated terms to continue.
          </p>
        }
        standalone>
        {terms ? (
          <>
            <Row>
              <Copy element="div" size="small" className={styles.summary}>
                <Markdown
                  source={terms}
                  escapeHtml={false}
                  renderers={{ link: LinkRenderer }}
                />
              </Copy>
            </Row>
            <Buttons reset="mobile" split>
              <InputCheckbox
                checked={agreement}
                flush
                highlight
                onChange={handleAgreementChange}>
                I agree to the terms above
              </InputCheckbox>
              <Button
                disabled={!agreement}
                event="review_terms"
                level="primary"
                onClick={handleSubmit}>
                Agree
              </Button>
            </Buttons>
          </>
        ) : <Spinner size={40} />}
      </Fieldset>
    </form>
  )
}

TermsForm.propTypes = {
  onSuccess: PropTypes.func,
}