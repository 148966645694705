import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Copy from '@fundrocket/common-web/components/Copy'

import styles from './Notice.module.scss'

export default function Notice({ className = null, color = 'yellow', ...props }) {
  return (
    <Copy
      {...props}
      className={classNames('Notice', styles.this, color && styles[`this---${color}`], className)}
    />
  )
}

Notice.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['yellow', 'grey']),
}
