import { useState, useEffect } from 'react'

const getWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

const getheight = () =>
  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

function useWindowSize({ width, height }) {
  // save current window width and height in the state object
  const [windowWidth, setWidth] = useState(width || getWidth())
  const [windowHeight, setHeight] = useState(height || getheight())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      // change width and height from the state object
      setWidth(getWidth())
      setHeight(getheight())
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return { width: windowWidth, height: windowHeight }
}

export default useWindowSize
