import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import formatDate from '@fundrocket/common/libs/format-date'

import Text from '@fundrocket/common-web/components/Text'

export default function DateTime({
  date = new Date(),
  format = 'display',
  className = null,
  ...props
}) {
  return (
    <Text
      {...props}
      dateTime={formatDate.timestamp(date)}
      element="time"
      title={formatDate.long(date)}
      className={classNames('DateTime', className)}>
      {formatDate[_.camelCase(format)](date)}
    </Text>
  )
}

DateTime.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  format: PropTypes.oneOf(['display', 'display-with-time', 'long', 'medium', 'short']),
}
