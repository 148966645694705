import classNames from 'classnames'
import React from 'react'
import ReactConfetti from 'react-confetti'

import useWindowSize from 'hooks/useWindowSize'

import styles from './Confetti.module.scss'

export default function Confetti(props) {
  const { width, height } = useWindowSize({ ...props })

  return (
    <ReactConfetti
      {...props}
      width={width}
      height={height}
      colors={[
        // scss vars
        '#c42b2b',
        '#fff7e0',
        '#048647',
        '#1867d1',
        // logo rocket flame
        '#f0894d',
        '#ed6654',
        '#de577d',
      ]}
      numberOfPieces={850}
      recycle={false}
      tweenDuration={18500}
      className={classNames('Confetti', styles.this)}
    />
  )
}
