import React from 'react'

import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldEmail2(props) {
  return (
    <Field2
      autoComplete="email"
      label="Email"
      name="email"
      placeholder="name@email.com"
      type="email"
      testid="email-input"
      // keep order
      {...props}
      className="FieldEmail2"
    />
  )
}