import { Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import validations from '@fundrocket/common/libs/validations'
import ButtonBack from '@fundrocket/common-web/components/ButtonBack'
import ButtonNext from '@fundrocket/common-web/components/ButtonNext'
import Buttons from '@fundrocket/common-web/components/Buttons'
import Columns from '@fundrocket/common-web/components/Columns'
import Field from '@fundrocket/common-web/components/Field'
import FieldEmail2 from '@fundrocket/common-web/components/FieldEmail2'
import FieldFirstName2 from '@fundrocket/common-web/components/FieldFirstName2'
import FieldLastName2 from '@fundrocket/common-web/components/FieldLastName2'
import FormValidationNotification from '@fundrocket/common-web/components/FormValidationNotification'

import events from 'constants/events'

export default function PersonalInformationForm({ back, email: propsEmail, isVisible, onSubmit }) {
  const firstNameInput = React.useRef(null)

  // TODO: custom hook?
  React.useEffect(() => {
    if (isVisible && firstNameInput?.current) firstNameInput.current.focus()
  }, [isVisible])

  function handleBack() {
    if (_.isFunction(back)) {
      back()
    } else {
      navigate(back)
    }
  }

  function handleSubmit(values, formikActions) {
    const { email, firstName } = values

    reporter.trackEvent(events.APPLICATION_PERSONAL_INFO_PROVIDED, {
      email,
      firstName,
      url: window.location.toString(),
    })

    submit({ formikActions, values })
  }

  function submit({ formikActions, values }) {
    if (onSubmit) onSubmit(values, formikActions.setSubmitting(false))
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: propsEmail,
        firstName: '',
        lastName: '',
      }}
      validationSchema={Yup.object({
        email: validations.emailOptional,
        firstName: validations.firstName,
        lastName: validations.lastName,
      })}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <Field>
            <Columns>
              <FieldFirstName2 autoFocus innerRef={firstNameInput} label="Legal First Name" />
              <FieldLastName2 label="Legal Last Name" />
            </Columns>
          </Field>
          <FieldEmail2 disabled />
          <Buttons split>
            {back && (
              <ButtonBack testid="personal-information-form--back-button" onClick={handleBack} />
            )}
            <ButtonNext
              disabled={formikProps.isSubmitting}
              testid="personal-information-form--submit-button"
              onClick={formikProps.submitForm}
            />
          </Buttons>
        </Form>
      )}
    </Formik>
  )
}

PersonalInformationForm.propTypes = {
  back: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  email: PropTypes.string,
  isVisible: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

PersonalInformationForm.defaultProps = {
  email: '',
}
