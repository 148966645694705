import React from 'react'

import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldBankAccountNumber(props) {
  return (
    <Field2
      inputMode="numeric"
      label="Bank Account Number"
      name="bankAccountNumber"
      placeholder="123456789"
      type="tel"
      testid="bank-account-number-input"
      // keep order
      {...props}
      className="FieldBankAccountNumber"
    />
  )
}