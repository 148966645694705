import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import inputValues from '@fundrocket/common/constants/input-values'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import Button from '@fundrocket/common-web/components/Button'
import EinTinNote from '@fundrocket/common-web/components/EinTinNote'
import FieldEinTin from '@fundrocket/common-web/components/FieldEinTin'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import notifications from '@fundrocket/common-web/libs/notifications'

import paths from 'constants/paths'
import urls from 'constants/urls'
import apiRequest from 'libs/api-request'

export default class EinTinForm extends React.Component {
  state = {
    disabled: null,
    einTin: '',
    einTinDisplay: '',
    einTinValid: false,
  }

  applicationId

  componentDidMount() {
    // TODO: mv to onRouteUpdate?
    reporter.setEventsContext({ url: window.location.toString() })

    this.getApplicationId()
  }

  getApplicationId = () => {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      const application = response.data[0]
      const { business_tax_id: businessTaxId, id: applicationId } = application

      if (businessTaxId) {
        notifications.notifyAndRedirect({
          delay: true,
          message: 'We already have your business tax ID',
          to: paths.DASHBOARD,
        })
        this.setState({ disabled: true })
        return
      }

      this.applicationId = applicationId
      reporter.setEventsContext({ applicationId })
    })
  }

  handleSubmit = () => {
    this.submit()
    this.setState({ disabled: true })
  }

  submit = () => {
    const { onSuccess } = this.props
    const { einTin } = this.state

    apiRequest({
      url: urls.API_APPLY_EIN_TIN,
      method: 'patch',
      data: {
        application_id: this.applicationId,
        business_tax_id: einTin,
      },
    })
      .then(() => {
        if (onSuccess) onSuccess()
      })
      .catch((error) => {
        this.setState({ disabled: false })
        reporter.trackError(error)
      })
  }

  render() {
    const { className } = this.props
    const { disabled, einTin, einTinDisplay, einTinValid } = this.state

    return (
      <form className={classNames('EinTinForm', className)} onSubmit={this.handleSubmit}>
        <Fieldset
          title="What is your business tax ID?"
          copy={
            <>
              <p>
                We need this to confirm your business and move forward in the application process.
              </p>
              <EinTinNote element="p" />
            </>
          }
          standalone>
          <FieldEinTin
            inputProps={{
              autoFocus: true,
              value: einTinDisplay,
              testid: 'business-tax-id-from--ein-tin-input',
              onBlur: () => {
                if (!einTinValid) return
                // ##-####### -> ••-•••####
                this.setState({
                  einTinDisplay: `${einTin
                    .substring(0, einTin.length - inputValues.EIN_TIN_NONREDACTED_CHARS)
                    .replace(/\d/g, '•')}${einTin.slice(-inputValues.EIN_TIN_NONREDACTED_CHARS)}`,
                })
              },
              onFocus: () => {
                if (einTinDisplay === einTin) return
                this.setState({ einTinDisplay: einTin })
              },
            }}
            single
            standalone
            onInvalidChange={(e) => {
              this.setState({
                einTin: e.target.value,
                einTinDisplay: e.target.value,
                einTinValid: false,
              })
            }}
            onValidChange={(e) => {
              this.setState({
                einTin: e.target.value,
                einTinDisplay: e.target.value,
                einTinValid: true,
              })
            }}>
            <Button level="primary" disabled={disabled || !einTinValid} onClick={this.handleSubmit}>
              Submit
            </Button>
          </FieldEinTin>
        </Fieldset>
      </form>
    )
  }
}

EinTinForm.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func,
}
