import React from 'react'

import Button from '@fundrocket/common-web/components/Button'

import { ReactComponent as ArrowLeftSvg } from './images/arrow---left.svg'

export default function ButtonBack(props) {
  return (
    <Button {...props} icon={<ArrowLeftSvg />} tabIndex="-1">
      Back
    </Button>
  )
}
