import React from 'react'

import { STATE_OPTIONS } from '@fundrocket/common/constants/input-options'
import Field2 from '@fundrocket/common-web/components/Field2'
import InputSelect2 from '@fundrocket/common-web/components/InputSelect2'

export default function FieldState2(props) {
  return (
    <Field2
      as={InputSelect2}
      autoComplete="address-level1"
      entity="state"
      label="State"
      name="state"
      options={STATE_OPTIONS}
      // keep order
      {...props}
      className="FieldState2"
    />
  )
}