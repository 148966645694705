import urls from 'constants/urls'
import RestApiService from 'services/RestApiService'

const dataMap = {
  getUser: {
    endpoint: urls.API_PERSONALIZATION,
    bodyAsForm: false,
    model: (model) => model.data,
  },
}

export default class PersonalizationService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  getUser = (code = '') => {
    return this.get('getUser', { append: code })
  }
}
