import { Router } from '@reach/router'
import React from 'react'

import RoutePrivate from 'components/RoutePrivate'
import BankAccount from 'app/pages/BankAccount'
import BankLink from 'app/pages/BankLink'
import BankRelink from 'app/pages/BankRelink'
import BusinessTaxId from 'app/pages/BusinessTaxId'
import Dashboard from 'app/pages/Dashboard'
import Documents from 'app/pages/Documents'
import Setup from 'app/pages/Setup'
import Terms from 'app/pages/Terms'
import paths from 'constants/paths'

export default function App() {
  return (
    <Router>
      <RoutePrivate path={paths.DASHBOARD} component={Dashboard} default />
      <RoutePrivate path={paths.BANK_ACCOUNT} component={BankAccount} />
      <RoutePrivate path={paths.BANK_LINK} component={BankLink} />
      <RoutePrivate path={paths.BANK_RELINK} component={BankRelink} />
      <RoutePrivate path={paths.BUSINESS_TAX_ID} component={BusinessTaxId} />
      <RoutePrivate path={paths.DOCUMENTS} component={Documents} />
      <RoutePrivate path={paths.SETUP} component={Setup} />
      <RoutePrivate path={paths.TERMS} component={Terms} />
    </Router>
  )
}
