import PropTypes from 'prop-types'
import React from 'react'

import copy from '@fundrocket/common/constants/copy'
import urls from '@fundrocket/common/constants/urls'
import Display from '@fundrocket/common-web/components/Display'
import EinTinNote from '@fundrocket/common-web/components/EinTinNote'
import FieldWithInput from '@fundrocket/common-web/components/FieldWithInput'
import HelpIcon from '@fundrocket/common-web/components/HelpIcon'
import InputEinTin from '@fundrocket/common-web/components/InputEinTin'
import assertions from '@fundrocket/common-web/libs/assertions'

export default function FieldEinTin({ optional, standalone, ...props }) {
  return (
    <FieldWithInput
      {...props}
      input={InputEinTin}
      label="EIN or TIN"
      labelNote={standalone ? null : <EinTinNote />}
      labelTooltip={
        standalone ? null : (
          <Display element="span" hide="mobile">
            <HelpIcon to={urls.IRS_EIN} tooltip={copy.EIN_TIN_TOOLTIP} />
          </Display>
        )
      }
      optional={optional}
      validate={(e) => {
        // TODO
        //if (optional) {
        //  assertions.einTinField(e.target.value)
        //} else {
        //  assertions.einTinRequiredField(e.target.value)
        //}
        // assertions.required(e.target.value)
        // assertions.einTin(e.target.value)

        if (optional) {
          assertions.einTin(e.target.value)
        } else {
          assertions.required(e.target.value)
          assertions.einTin(e.target.value)
        }
      }}
      className="FieldEinTin"
    />
  )
}

FieldEinTin.propTypes = {
  inputProps: PropTypes.object.isRequired,
  onInvalidChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  standalone: PropTypes.bool,
}
