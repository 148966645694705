import { format as dateFnsFormat, isDate, parse } from 'date-fns'

const fallbackDate = '--'
const VALID_INPUT_DATE_FORMATS = [
  'yyyy-MM-dd',
  'dd-MM-yyyy',
  'MM-dd-yyyy',
  'dd/MM/yyyy',
  'MM/dd/yyyy',
]

const format = (date, formatString = 'MMMM dd, yyyy') => {
  if (!date) return ''

  let value = date

  if (!isDate(value)) {
    for (const dateFormat of VALID_INPUT_DATE_FORMATS) {
      try {
        value = parse(date, dateFormat, new Date())
        if (value.toString() !== 'Invalid Date') break
      } catch {
        value = fallbackDate
      }
    }

    if (!isDate(value)) return fallbackDate
  }

  try {
    return dateFnsFormat(value, formatString)
  } catch {
    return fallbackDate
  }
}

const api = (date) => format(date, 'yyyy-MM-dd')
const display = (date) => format(date)
const displayWithTime = (date) => format(date, 'MMM dd, yyyy h:mma')
const input = (date) => format(date, 'MM/dd/yyyy')
const long = (date) => format(date, 'EEE MMMM dd, yyyy')
const medium = (date) => format(date, 'MM/dd/yy')
const short = (date) => format(date, 'MMM dd')
const timestamp = (date) => format(date, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)

export default {
  api,
  display,
  displayWithTime,
  input,
  long,
  short,
  medium,
  timestamp,
}
