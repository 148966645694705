import { useFormikContext } from 'formik'
import React from 'react'

import notifications from '@fundrocket/common-web/libs/notifications'

export default function FormValidationNotification() {
  const formikContext = useFormikContext()

  React.useEffect(() => {
    if (_.isEmpty(formikContext.errors)) return

    notifications.notify({
      level: 'error',
      message: 'Please update the invalid fields',
    })
  }, [formikContext.isSubmitting])

  return null
}