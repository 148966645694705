import classNames from 'classnames'
import { Field as FormikField, useField } from 'formik'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import Display from '@fundrocket/common-web/components/Display'
import Field from '@fundrocket/common-web/components/Field'
import HelpIcon from '@fundrocket/common-web/components/HelpIcon'
import Input from '@fundrocket/common-web/components/Input'
import Label from '@fundrocket/common-web/components/Label'
import Text from '@fundrocket/common-web/components/Text'

import styles from './Field2.module.scss'

export default function Field2({
  children,
  className,
  error,
  flush,
  //invisible,
  label,
  labelAsPlaceholder,
  labelHidden,
  labelNote,
  labelTooltip,
  optional,
  placeholder,
  ...props
}) {
  // can't destructure because of `useField`
  const { name } = props

  const [field, meta] = useField(props)

  const id = _.uniqueId('id-')
  const isError = error || (meta.touched && !_.isNil(meta.error))

  return (
    <Field
      error={isError}
      flush={flush}
      className={classNames(
        'Field2',
        //invisible && styles['this---invisible'],
        className
      )}>
      <Label
        append={
          <>
            {_.isString(labelTooltip) && <HelpIcon tooltip={labelTooltip} />}
            {labelTooltip && !_.isString(labelTooltip) && labelTooltip}
          </>
        }
        hidden={labelHidden || labelAsPlaceholder}
        htmlFor={id}
        optional={optional}>
        {label}
      </Label>
      {labelNote && (
        <Display show="mobile">
          <Text element="p" size="small" className={styles['label-note']}>
            {labelNote}
          </Text>
        </Display>
      )}
      <FormikField
        {...props}
        id={id}
        label={label}
        name={name}
        placeholder={labelAsPlaceholder ? label : placeholder}
      />
      {isError && (
        <Text align="left" color="red" element="div" className={styles.validation}>
          {error || meta.error}
        </Text>
      )}
      {children}
    </Field>
  )
}

Field2.propTypes = {
  // TODO
  //as: PropTypes.node,
  as: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  flush: PropTypes.bool,
  //invisible: PropTypes.bool,
  label: PropTypes.node.isRequired,
  labelAsPlaceholder: PropTypes.bool,
  labelHidden: PropTypes.bool,
  labelNote: PropTypes.node,
  labelTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  //showErrorBeforeBlur: PropTypes.bool,
  testid: PropTypes.string,
}

Field2.defaultProps = {
  as: Input,
}