import { isEmpty, sortBy } from 'lodash'

const getInstitutionById = (institutions, id) =>
  institutions.find((item) => item.institution_id === id)

const getItemInactive = (items) => items.find((item) => !item.is_active)

const getItemsInactive = (items) => items.filter((item) => !item.is_active)

const hasItemsInactive = (items) => !isEmpty(getItemsInactive(items))

const order = (items) => sortBy(items, (item) => item.created)

export default {
  getInstitutionById,
  getItemInactive,
  getItemsInactive,
  hasItemsInactive,
  order,
}
