import urls from 'constants/urls'
import RestApiService from 'services/RestApiService'

const dataMap = {
  getPresignedUploadParams: {
    endpoint: urls.API_AWS_S3_UPLOAD,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  getDocuments: {
    endpoint: urls.API_GET_S3_DOCUMENT,
    bodyAsForm: false,
    model: (model) => model.data.documents,
  },
}

export default class AWSUploadService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  getPresignedUploadParams = (applicationId, name, userId) =>
    this.get('getPresignedUploadParams', { applicationId, name, userId })

  getDocuments = () => this.get('getDocuments')
}
