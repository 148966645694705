import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'

import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import validations from '@fundrocket/common/libs/validations'
import ButtonBack from '@fundrocket/common-web/components/ButtonBack'
import ButtonNext from '@fundrocket/common-web/components/ButtonNext'
import Buttons from '@fundrocket/common-web/components/Buttons'
import Columns from '@fundrocket/common-web/components/Columns'
import Field from '@fundrocket/common-web/components/Field'
import Field2 from '@fundrocket/common-web/components/Field2'
import FieldBusinessName2 from '@fundrocket/common-web/components/FieldBusinessName2'
import FieldEinTin2 from '@fundrocket/common-web/components/FieldEinTin2'
import FieldEntityType2 from '@fundrocket/common-web/components/FieldEntityType2'
import FieldState2 from '@fundrocket/common-web/components/FieldState2'
import FieldYear2 from '@fundrocket/common-web/components/FieldYear2'
import FormValidationNotification from '@fundrocket/common-web/components/FormValidationNotification'

import events from 'constants/events'

export default function BusinessInformationForm({
  businessName: propsBusinessName,
  isVisible,
  onBack,
  onSubmit,
}) {
  const businessNameInput = React.useRef(null)

  // TODO: custom hook?
  React.useEffect(() => {
    if (isVisible && businessNameInput?.current) businessNameInput.current.focus()
  }, [isVisible])

  function handleBack() {
    onBack()
  }

  function handleSubmit(values, formikActions) {
    const { businessName, businessNameDba } = values

    reporter.trackEvent(events.APPLICATION_BUSINESS_INFO_PROVIDED, {
      businessName,
      businessNameDba,
      url: window.location.toString(),
    })

    submit({ formikActions, values })
  }

  function submit({ formikActions, values }) {
    if (onSubmit) onSubmit(values, formikActions.setSubmitting(false))
  }

  return (
    <Formik
      initialValues={{
        businessName: propsBusinessName,
        businessNameDba: '',
        einTin: '',
        entityType: '',
        registrationState: '',
        registrationYear: '',
      }}
      validationSchema={Yup.object({
        businessName: validations.required,
        einTin: validations.einTinOptional,
        entityType: validations.required,
        registrationState: validations.required,
        registrationYear: validations.registrationYear,
      })}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <FieldBusinessName2
            innerRef={businessNameInput}
            testid="business-information-form--business-name-input"
          />
          <Field2
            label="Business Name DBA"
            optional
            name="businessNameDba"
            placeholder="John’s Cafe"
            testid="business-information-form--business-name-dba-input"
          />
          <Field>
            <Columns>
              <FieldYear2
                label="First Year of Business"
                name="registrationYear"
                testid="business-information-form--registration-year-input"
              />
              <FieldState2
                label="Registration State"
                name="registrationState"
                testid="business-information-form--state-input"
              />
            </Columns>
          </Field>
          <Field>
            <Columns>
              <FieldEntityType2 testid="business-information-form--entity-input" />
              <FieldEinTin2 optional testid="business-information-form--ein-tin-input" />
            </Columns>
          </Field>
          <Buttons split>
            {onBack && (
              <ButtonBack testid="business-information-form--back-button" onClick={handleBack} />
            )}
            <ButtonNext
              disabled={formikProps.isSubmitting}
              testid="business-information-form--submit-button"
              onClick={formikProps.submitForm}
            />
          </Buttons>
        </Form>
      )}
    </Formik>
  )
}

BusinessInformationForm.propTypes = {
  businessName: PropTypes.string,
  isVisible: PropTypes.bool,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
}

BusinessInformationForm.defaultProps = {
  businessName: '',
}
