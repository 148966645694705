import formatNumber from '@fundrocket/common/libs/format-number'

export const BANK_ACCOUNT_TYPE_OPTIONS = [
  {
    label: 'Savings',
    value: 'savings',
  },
  {
    label: 'Checking',
    value: 'checking',
  },
]

export const ENTITY_TYPE_OPTIONS = [
  {
    label: 'C Corp',
    value: 'C corporation',
  },
  {
    label: 'S Corp',
    value: 'S corporation',
  },
  {
    label: 'LLC',
    value: 'Limited Liability Company',
  },
  {
    label: 'Partnership',
    value: 'General Partnership',
  },
  {
    label: 'Limited Partnership',
    value: 'Limited Partnership',
  },
  {
    label: 'Limited Liability Partnership',
    value: 'Limited Liability Partnership',
  },
  {
    label: 'Sole Proprietorship',
    value: 'Sole Proprietorship',
  },
]

export const REQUESTED_AMOUNT_OPTIONS = [
  {
    label: formatNumber.currency(5000),
    value: 5000,
  },
  {
    label: formatNumber.currency(10000),
    value: 10000,
  },
  {
    label: formatNumber.currency(15000),
    value: 15000,
  },
  {
    label: formatNumber.currency(20000),
    value: 20000,
  },
  {
    label: formatNumber.currency(25000),
    value: 25000,
  },
  {
    label: formatNumber.currency(30000),
    value: 30000,
  },
  {
    label: formatNumber.currency(35000),
    value: 35000,
  },
  {
    label: formatNumber.currency(40000),
    value: 40000,
  },
  {
    label: formatNumber.currency(45000),
    value: 45000,
  },
  {
    label: formatNumber.currency(50000),
    value: 50000,
  },
]

export const STATE_OPTIONS = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
]

export const INDUSTRY_OPTIONS = [
  {
    label: 'Business Purchases & Franchise Fees',
    value: '200',
  },
  {
    label: 'Cars, Boats, Trucks, Planes',
    value: '300',
  },
  {
    label: 'Clubs & Memberships',
    value: '400',
  },
  {
    label: 'Commercial rent',
    value: '201',
  },
  {
    label: 'Construction & Maintenance',
    value: '301',
  },
  {
    label: 'General Inventory',
    value: '202',
  },
  {
    label: 'Industrial',
    value: '203',
  },
  {
    label: 'Insurance',
    value: '100',
  },
  {
    label: 'Labour & Payroll',
    value: '204',
  },
  {
    label: 'Medical',
    value: '101',
  },
  {
    label: 'Mortgage & Home Equity Line of Credit',
    value: '302',
  },
  {
    label: 'Other Business Services',
    value: '205',
  },
  {
    label: 'Personal Services',
    value: '401',
  },
  {
    label: 'Professional Services',
    value: '102',
  },
  {
    label: 'Residential rent & Condo Fees',
    value: '303',
  },
  {
    label: 'Shipping',
    value: '103',
  },
  {
    label: 'Student Loans',
    value: '402',
  },
  {
    label: 'Taxes & Government Fees',
    value: '104',
  },
  {
    label: 'Telecom',
    value: '304',
  },
  {
    label: 'Tuition & Childcare',
    value: '403',
  },
  {
    label: 'Utilities',
    value: '305',
  },
]
