import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Box.module.scss'

export default function Box({ children, className, color, size }) {
  return (
    <div
      className={classNames(
        'Box',
        styles.this,
        color && styles['this---color'],
        color && styles[`this---${color}`],
        size && styles[`this---${size}`],
        className
      )}>
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['red', 'blue']),
  size: PropTypes.oneOf(['large', 'small']),
}

Box.defaultProps = {
  size: 'large',
}
