import {
  Listbox,
  ListboxOption,
} from '@reach/listbox'
import classNames from 'classnames'
import { useField } from 'formik'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import '@reach/listbox/styles.css'

import { ReactComponent as ChevronDownSvg } from './images/chevron---down.svg'
import './InputSelect2.scss'

const DEFAULT_VALUE = 'default'

export default function InputSelect2({
  className,
  entity: propsEntity,
  label,
  name,
  onChange,
  options,
  placeholder: propsPlaceholder,
  value: propsValue,
  ...props
}) {
  // TODO: why doesn't defaultProps work?
  const [value, setValue] = React.useState(propsValue || DEFAULT_VALUE)
  const [field, meta, helpers] = useField(name)

  const entity = propsEntity || label
  const placeholder = propsPlaceholder || (entity ? `Select ${entity.toLowerCase()}…` : null)
  const isPlaceholder = value === DEFAULT_VALUE

  function handleChange(valueNew) {
    setValue(valueNew)
    helpers.setValue(valueNew)
  }

  return (
    <Listbox
      {...props}
      arrow={<ChevronDownSvg className="InputSelect--arrow" />}
      value={value}
      className={classNames('InputSelect', isPlaceholder && 'InputSelect---placeholder', className)}
      onChange={handleChange}>
      <ListboxOption disabled value={DEFAULT_VALUE}>
        {placeholder}
      </ListboxOption>
      {options.map((item) => (
        <ListboxOption key={item.value} value={item.value}>
          {item.label}
        </ListboxOption>
      ))}
    </Listbox>
  )
}

InputSelect2.propTypes = {
  entity: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
}

InputSelect2.defaultProps = {
  value: DEFAULT_VALUE,
}