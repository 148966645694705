import paths from 'constants/paths'
import RestApiService from 'services/RestApiService'

const dataMap = {
  getFundingSummary: {
    endpoint: paths.FUNDING_SUMMARY_MD,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  getFundingSummaryInbound: {
    endpoint: paths.FUNDING_SUMMARY_INBOUND_MD,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  getFundingSummaryRenewal: {
    endpoint: paths.FUNDING_SUMMARY_RENEWAL_MD,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  getFundingSummaryWomply1000: {
    endpoint: paths.FUNDING_SUMMARY_WOMPLY1000,
    bodyAsForm: false,
    model: (model) => model.data,
  },
  getFundingSummaryBusinessSetupTerms: {
    endpoint: paths.SETUP_TERMS,
    bodyAsForm: false,
    model: (model) => model.data,
  },
}

export default class FundingSummaryService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  getFundingSummary = () => {
    return this.get('getFundingSummary')
  }

  getFundingSummaryInbound = () => {
    return this.get('getFundingSummaryInbound')
  }

  getFundingSummaryRenewal = () => {
    return this.get('getFundingSummaryRenewal')
  }

  getFundingSummaryWomply1000 = () => {
    return this.get('getFundingSummaryWomply1000')
  }

  getFundingSummaryBusinessSetupTerms = () => {
    return this.get('getFundingSummaryBusinessSetupTerms')
  }
}
