import React from 'react'

import Field2 from '@fundrocket/common-web/components/Field2'

export default function FieldLastName2(props) {
  return (
    <Field2
      autoComplete="family-name"
      label="Last Name"
      //name="lname"
      name="lastName"
      placeholder="Smith"
      testid="last-name-input"
      // keep order
      {...props}
      className="FieldLastName2"
    />
  )
}