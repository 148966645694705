import PropTypes from 'prop-types'
import React from 'react'

import BankButtonModal from '@fundrocket/common-web/components/BankButton/BankButtonModal'
import ButtonWithModal from '@fundrocket/common-web/components/ButtonWithModal'

export default function BankButton({ buttonInstantProps, buttonManualProps, ...props }) {
  return (
    <ButtonWithModal
      {...props}
      modal={
        <BankButtonModal
          buttonInstantProps={buttonInstantProps}
          buttonManualProps={buttonManualProps}
        />
      }>
      Link Bank
    </ButtonWithModal>
  )
}

BankButton.propTypes = {
  buttonInstantProps: PropTypes.object.isRequired,
  buttonManualProps: PropTypes.object.isRequired,
}
