import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import Markdown from 'react-markdown'

import formatPhone from '@fundrocket/common/libs/format-phone'
import reporter from '@fundrocket/common/libs/reporter/adapter/web'
import verifyPhone from '@fundrocket/common/libs/verify-phone'
import BankButton from '@fundrocket/common-web/components/BankButton'
import Button from '@fundrocket/common-web/components/Button'
import ButtonBack from '@fundrocket/common-web/components/ButtonBack'
import ButtonNext from '@fundrocket/common-web/components/ButtonNext'
import Buttons from '@fundrocket/common-web/components/Buttons'
import Container from '@fundrocket/common-web/components/Container'
import Copy from '@fundrocket/common-web/components/Copy'
//import Display from '@fundrocket/common-web/components/Display'
import Field from '@fundrocket/common-web/components/Field'
import Fieldset from '@fundrocket/common-web/components/Fieldset'
import Head from '@fundrocket/common-web/components/Head'
import Heading from '@fundrocket/common-web/components/Heading'
import InputCheckbox from '@fundrocket/common-web/components/InputCheckbox'
import InputRadiosBars from '@fundrocket/common-web/components/InputRadiosBars'
import MediaObject from '@fundrocket/common-web/components/MediaObject'
import Module1 from '@fundrocket/common-web/components/Module1'
import Notice from '@fundrocket/common-web/components/Notice'
import Progress from '@fundrocket/common-web/components/Progress'
import Row from '@fundrocket/common-web/components/Row'
import SecurityNotice from '@fundrocket/common-web/components/SecurityNotice'
import Text from '@fundrocket/common-web/components/Text'
import View from '@fundrocket/common-web/components/View'
import notifications from '@fundrocket/common-web/libs/notifications'
import scroll from '@fundrocket/common-web/libs/scroll'

import BankAccountForm from 'components/BankAccountForm'
import BusinessInformationForm from 'components/BusinessInformationForm'
import Confetti from 'components/Confetti'
import ErrorBoundary from 'components/ErrorBoundary'
import Header from 'components/Header'
import Help from 'components/Help'
import Layout from 'components/Layout'
import Link from 'components/Link'
import PersonalInformationForm from 'components/PersonalInformationForm'
import events from 'constants/events'
import applications from 'constants/applications'
import paths from 'constants/paths'
import urls from 'constants/urls'
import { UserContext } from 'contexts/User'
import apiRequest from 'libs/api-request'
import bankAccounts from 'libs/bank-accounts'
import logging from 'libs/logging'
import pay from 'libs/pay'
import FundingSummaryService from 'services/FundingSummaryService'
import OfferService from 'services/OfferService'
import PersonalizationService from 'services/PersonalizationService'

import styles from './Setup.module.scss'

const fundingSummaryService = new FundingSummaryService()
const offerService = new OfferService()
const personalizationService = new PersonalizationService()

const commonSteps = [
  applications.STATUS_PERSONAL,
  applications.STATUS_BUSINESS,
  applications.STATUS_BANK_AUTH,
  applications.STATUS_SUMMARY,
]

const variants = {
  [pay.CAMPAIGN_ID]: {
    //fundingSummaryPath: paths.SETUP_TERMS,
    metaTitle: 'Womply Pay business setup',
    steps: [...commonSteps, 'Finished'],
    title: 'Womply Pay business setup',
  },
}

function SetupStep({ children }) {
  return (
    <div>
      <Text color="white" size="small" weight="semibold" className={styles.step}>
        Step {children} of 4
      </Text>
    </div>
  )
}

SetupStep.propTypes = {
  children: PropTypes.number.isRequired,
}

export default class Setup extends React.Component {
  static contextType = UserContext

  phones = []

  steps = [...commonSteps, 'Finish']

  userExists = false

  state = {
    accounts: null,
    accountSelectedId: null,
    agreement: false,
    applicationId: null,
    bankProvider: '',
    blid: null,
    businessName: '',
    businessNameDba: '',
    campaignId: null,
    einTin: '',
    email: '',
    entityType: '',
    firstName: '',
    fundingSummary: null,
    lastName: '',
    metaTitle: 'Womply business setup',
    offerIsFinal: null,
    phone: '',
    plaid: null,
    progressCurrentStep: 0,
    registrationState: '',
    registrationYear: '',
    status: this.steps[0],
    statusPostAuth: null,
    steps: this.steps,
    title: 'Womply business setup',
  }

  componentDidMount() {
    const { location } = this.props
    const { search: queryString } = location
    let campaignId

    reporter.setEventsContext({ url: window.location.toString() })

    if (pay.isPay(window.location)) {
      campaignId = pay.CAMPAIGN_ID
    }

    const { applicationId, applicationReferenceId, blid, email, phone, status } = qs.parse(
      queryString,
      {
        ignoreQueryPrefix: true,
      }
    )

    if (campaignId in variants) {
      this.setState({
        campaignId,
        ...variants[campaignId],
      })
    } else {
      navigate(paths.DASHBOARD)
    }

    this.getApplications()
    this.getAuthStatus()
    if (applicationReferenceId) this.getUser(applicationReferenceId)

    this.setState({
      applicationId,
      blid,
      email,
      // TODO: what if this collides with `getAuthStatus` phone?
      phone,
      statusPostAuth: status,
    })
  }

  getAuthStatus = async () => {
    const verifyStatus = await verifyPhone.status()

    if (verifyStatus) {
      this.setState({ phone: verifyStatus.phone })
      this.context.updateUser({ user: verifyStatus })
      this.handleAuthed()
    }
  }

  getApplications = () => {
    apiRequest({
      url: urls.API_APPLICATIONS,
      method: 'get',
    }).then((response) => {
      console.log('response', response)
      logging.toConsole({
        message: 'getApplications',
        value: response,
      })
      const application = response.data[0]

      if (application && applications.SUBMISSION_STATUSES_TERMINAL.includes(application.status)) {
        notifications.notifyAndRedirect({
          level: 'warning',
          message: 'Your business setup application has already been submitted',
          to: navigate(paths.DASHBOARD),
        })
      }
    })
  }

  getUser = (applicationReferenceId) => {
    personalizationService
      .getUser(applicationReferenceId)
      .then((data) => {
        const { business_name: businessName, first_name: firstName, last_name: lastName } = data

        this.setState({
          businessName,
          firstName,
          lastName,
        })

        this.context.updateUser({
          businessName,
          firstName,
          lastName,
        })
      })
      .catch((error) => {
        reporter.trackError(error)
      })
  }

  handleAuthed = () => {
    const { statusPostAuth } = this.state

    if (statusPostAuth === applications.STATUS_SUMMARY) {
      this.getFundingSummary()
    } else if (statusPostAuth === applications.STATUS_BANK_AUTH) {
      this.showBankAuth()
    } else if (statusPostAuth === applications.STATUS_BUSINESS) {
      this.showBusinessInformationForm()
    } else {
      this.showPersonalInformationForm()
    }
  }

  getFundingSummary = () => {
    //const { fundingSummaryPath } = this.state

    fundingSummaryService
      .getFundingSummaryBusinessSetupTerms()
      .then((data) => {
        const { businessName = '', firstName, lastName } = this.state

        const fundingSummary = data
          .replace(/{businessName}/g, businessName)
          .replace(/{firstName}/g, firstName)
          .replace(/{lastName}/g, lastName)

        this.setState({ fundingSummary }, () => {
          this.showSummary()
        })
      })
      .catch((error) => {
        reporter.trackError(error)
      })
  }

  submit = (callback) => {
    // TODO: this would be a nice safe guard though probably not needed…breaks tests
    //if (!this.context.user) return

    const {
      agreement,
      applicationId,
      bankProvider,
      blid,
      businessName,
      businessNameDba,
      campaignId,
      einTin,
      email,
      entityType,
      firstName,
      fundingSummary,
      lastName,
      offerIsFinal,
      phone,
      plaid,
      registrationState,
      registrationYear,
    } = this.state

    offerService
      .applyOffer({
        application_id: applicationId,
        banking_info: plaid
          ? {
              provider: 'plaid',
              data: plaid,
            }
          : bankProvider
          ? {
              provider: bankProvider,
            }
          : null,
        business_email: email,
        business_entity_type: entityType,
        business_name: businessNameDba ? `${businessName}:DBA:${businessNameDba}` : businessName,
        business_registration_state: registrationState,
        business_registration_year: Number.parseInt(registrationYear, 10),
        business_tax_id: einTin,
        campaign_id: campaignId,
        first_name: firstName,
        funding_summary: fundingSummary,
        is_final: offerIsFinal,
        is_inbound: true,
        last_name: lastName,
        payment_processor: blid,
        phone: formatPhone.api(phone),
        tos_opt_in: agreement,
      })
      .then((response) => {
        if (callback) callback()

        if (!applicationId && response.id) {
          this.setState({ applicationId: response.id })
        }
      })
      .catch((error) => {
        if (_.get(error, 'response.status') === 409) {
          notifications.notifyAndRedirect({
            level: 'warning',
            message: 'Your business setup application has already been submitted',
            to: navigate(paths.DASHBOARD),
          })
        } else {
          notifications.notify({
            level: 'error',
            message: 'There was a problem with your submission. Please try again later.',
          })

          reporter.trackError(error)
        }
      })
  }

  updateFormAndProgress = (status) => {
    const { steps } = this.state

    this.setState({ status })

    if (status === applications.STATUS_SUBMITTED) status = _.last(steps)

    if (steps.includes(status)) {
      this.setState({ progressCurrentStep: steps.indexOf(status) })
    }

    scroll.toTop()
  }

  handlePersonalInformationFormSubmit = (data, callback) => {
    this.setState({ ...data }, () => {
      this.submit(() => this.showBusinessInformationForm(callback))
    })
  }

  handleBusinessInformationFormBack = () => {
    this.showPersonalInformationForm()
  }

  handleBusinessInformationFormSubmit = (data, callback) => {
    this.setState({ ...data }, () => {
      this.submit(() => this.showBankAuth(callback))
    })
  }

  handleBankAuthBack = () => {
    this.showBusinessInformationForm()
  }

  handlePlaidExit = (error, metadata) => {
    if (metadata.status === 'institution_not_found') {
      notifications.notify({
        level: 'warning',
        message:
          'We’re sorry we don’t have coverage for your bank. We will notify you if this changes.',
      })
    }

    if (error && error.error_code === 'ITEM_LOCKED' && error.error_type === 'ITEM_ERROR') {
      notifications.notify({
        level: 'warning',
        message: 'Your account is locked! Visit your bank’s site and unlock your account.',
      })
    }
  }

  handlePlaidSuccess = (publicKey, metadata) => {
    const { accounts } = metadata

    const checkingAccounts = bankAccounts.getAccounts(accounts)

    if (!bankAccounts.hasAccounts(checkingAccounts)) {
      notifications.notify({
        level: 'warning',
        message: 'Your don’t have a business checking account with this bank',
      })
      return
    }

    this.setState(
      {
        accounts: checkingAccounts,
        plaid: {
          ...metadata,
          accounts,
        },
      },
      () => {
        if (_.size(checkingAccounts) === 1) {
          this.handleSelectBankAccount(checkingAccounts[0].id)
        } else {
          this.showBankAccounts()
        }
      }
    )
  }

  handleBankSkip = () => {
    this.setState(
      {
        bankProvider: 'manual',
      },
      () => {
        this.submit(this.showBankManual)
      }
    )
  }

  handleBankAccountsBack = () => {
    this.showBankAuth()
  }

  handleSelectBankAccount = (bankAccountId) => {
    const { accounts, accountSelectedId, phone } = this.state

    const account = _.find(accounts, { id: accountSelectedId || bankAccountId })

    this.setState(
      (prevState) => ({
        plaid: {
          ...prevState.plaid,
          account,
          // eslint-disable-next-line camelcase
          account_id: account.id,
        },
      }),
      () => {
        reporter.trackEvent(events.APPLICATION_BANK_LINKED, {
          phone: formatPhone.e164(phone),
        })
        this.submit(this.getFundingSummary)
      }
    )
  }

  handleBankManualBack = () => {
    this.showBankAuth()
  }

  handleBankManualSubmit = (values) => {
    this.setState({ ...values }, () => {
      this.submit(this.getFundingSummary)
    })
  }

  handleAgreementChange = () => {
    this.setState((prevState) => ({ agreement: !prevState.agreement }))
  }

  handleSubmit = () => {
    if (!this.context.user) return

    this.setState(
      {
        offerIsFinal: true,
      },
      () => {
        reporter.trackEvent(events.APPLICATION_SUBMITTED)
        this.submit(this.showSubmitted)
      }
    )
  }

  showPersonalInformationForm = () => {
    this.updateFormAndProgress(applications.STATUS_PERSONAL)
  }

  showBusinessInformationForm = (callback) => {
    this.updateFormAndProgress(applications.STATUS_BUSINESS)
    if (callback) callback()
  }

  showBankAuth = (callback) => {
    this.updateFormAndProgress(applications.STATUS_BANK_AUTH)
    if (callback) callback()
  }

  showBankAccounts = () => {
    this.updateFormAndProgress(applications.STATUS_BANK_ACCOUNTS)
  }

  showBankManual = () => {
    this.updateFormAndProgress(applications.STATUS_BANK_MANUAL)
  }

  showSummary = () => {
    this.updateFormAndProgress(applications.STATUS_SUMMARY)
  }

  showSubmitted = () => {
    this.updateFormAndProgress(applications.STATUS_SUBMITTED)
  }

  renderBankAuth = () => {
    const { statusPostAuth, title } = this.state

    const allowBack = !statusPostAuth || statusPostAuth === applications.STATUS_BUSINESS

    return (
      <Fieldset
        title={
          <>
            <Heading level="1" testid="application--title">
              {title}
            </Heading>
            <SetupStep children={3} />
            Link your business bank account
          </>
        }
        copy={
          <Copy>
            <Text element="p" flush>
              This allows us to:
            </Text>
            <ul>
              <li>Verify your business</li>
              <li>Review your transaction history</li>
              <li>Deposit your money</li>
            </ul>
          </Copy>
        }>
        <Row size="small">
          <Buttons split>
            {allowBack && (
              <ButtonBack
                testid="application--bank-auth--back-button"
                onClick={this.handleBankAuthBack}
              />
            )}
            <BankButton
              level="primary"
              buttonInstantProps={{
                eventPrefix: 'Pay-',
                testid: 'application--bank-auth--plaid-button',
                onExit: this.handlePlaidExit,
                onSuccess: this.handlePlaidSuccess,
              }}
              buttonManualProps={{
                onClick: this.handleBankSkip,
              }}
              testid="application--bank-auth--bank-button"
            />
          </Buttons>
        </Row>
        <Row size="xsmall">
          <SecurityNotice />
        </Row>
        <Help prepend="Can’t link your bank?" />
      </Fieldset>
    )
  }

  renderBankAccounts = () => {
    const { accounts, accountSelectedId } = this.state

    const accountsInputs = []

    accounts.forEach((item) => {
      const { id, mask: accountNumber, name } = item

      const account = {
        body: (
          <MediaObject center figure="right" reset="mobile">
            <Text element="h3" truncate>
              {name}
            </Text>
            <div>******{accountNumber}</div>
          </MediaObject>
        ),
        value: id,
      }

      accountsInputs.push(account)
    })

    return (
      <Fieldset
        title="Select your business checking account"
        copy={
          <p>
            This is where the money is going to be deposited—please select the business checking
            account associated with your business.{' '}
            <Text weight="semibold">
              Your setup can’t be approved if this information is not verifiable.
            </Text>
          </p>
        }>
        <Field>
          <InputRadiosBars
            inputs={accountsInputs}
            onChange={(value) => this.setState({ accountSelectedId: value })}
          />
        </Field>
        <Row size="xsmall">
          <Notice size="small">
            Don’t see the account you’re looking for?{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onClick={this.handleBankAccountsBack}>
              <Text element="u">Link a different bank</Text>
            </Link>
          </Notice>
        </Row>
        <Buttons split>
          <ButtonBack onClick={this.handleBankAccountsBack} />
          <ButtonNext disabled={!accountSelectedId} onClick={this.handleSelectBankAccount} />
        </Buttons>
      </Fieldset>
    )
  }

  renderSummary() {
    const { agreement, fundingSummary, title } = this.state

    function LinkRenderer({ children, href }) {
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={href} target="_blank">
          {children}
        </a>
      )
    }

    return (
      <Fieldset
        title={
          <>
            <Heading level="1" testid="application--title">
              {title}
            </Heading>
            <SetupStep children={4} />
            You’re almost there!
          </>
        }
        copy={
          <p>
            In order to complete your business setup, please review the terms and conditions below.
          </p>
        }>
        <Row>
          <Copy element="div" size="small" className={styles.summary}>
            <Markdown
              source={fundingSummary}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          </Copy>
        </Row>
        <Buttons reset="mobile" split>
          <InputCheckbox
            checked={agreement}
            flush
            highlight
            onChange={this.handleAgreementChange}
            testid="setup-agreement-form--checkbox">
            I agree to the terms above
          </InputCheckbox>
          <Button
            disabled={!agreement}
            event="review_terms"
            level="primary"
            onClick={this.handleSubmit}
            testid="setup-agreement-form--button-submit">
            Complete Setup
          </Button>
        </Buttons>
      </Fieldset>
    )
  }

  renderSubmitted() {
    return (
      <>
        <Confetti />
        <Module1
          title="Thank you for submitting"
          button="Go to Womply Dashboard"
          buttonTo={urls.WOMPLY_DASHBOARD}>
          <Copy>
            <p>
              You’re all set! You may close this window or go back to the{' '}
              <Link to={urls.WOMPLY_DASHBOARD}>Womply Dashboard</Link>.
            </p>
          </Copy>
        </Module1>
      </>
    )
  }

  render() {
    const {
      applicationId,
      email,
      metaTitle,
      progressCurrentStep,
      status,
      statusPostAuth,
      steps,
      title,
    } = this.state

    const progressProps = {
      currentStep: progressCurrentStep,
      //steps: steps.map((item) => item.replace(applications.STATUS_SUBMITTED, _.last(steps))),
      steps: steps.map((item) =>
        item
          .replace(applications.STATUS_SUMMARY, 'Terms')
          .replace(applications.STATUS_SUBMITTED, 'Finished')
      ),
    }

    return (
      <Layout className={classNames('Setup', styles.this)}>
        <Head title={metaTitle} preconnect={applications.preconnectUrls} />
        <Header />
        <div className={styles.inner}>
          <div className={styles.sidebar}>
            <Progress {...progressProps} axis="y" />
          </div>
          <View className={styles.content}>
            <Container size="medium">
              <ErrorBoundary>
                {/* TODO
                <Display show="tablet">
                  <Row size="small">
                    <Progress {...progressProps} axis="x" />
                  </Row>
                </Display>
                */}
                <Fieldset
                  title={
                    <>
                      <Heading level="1" testid="application--title">
                        {title}
                      </Heading>
                      <SetupStep children={1} />
                      Your personal information
                    </>
                  }
                  // TODO
                  copy={<p />}
                  hide={status !== applications.STATUS_PERSONAL}>
                  <PersonalInformationForm
                    email={email}
                    isVisible={status === applications.STATUS_PERSONAL}
                    onSubmit={this.handlePersonalInformationFormSubmit}
                  />
                </Fieldset>

                <Fieldset
                  title={
                    <>
                      <Heading level="1" testid="application--title">
                        {title}
                      </Heading>
                      <SetupStep children={2} />
                      Your business information
                    </>
                  }
                  copy={<p>Please enter the information below to help us verify your business.</p>}
                  hide={status !== applications.STATUS_BUSINESS}>
                  <BusinessInformationForm
                    isVisible={status === applications.STATUS_BUSINESS}
                    onBack={statusPostAuth ? null : this.handleBusinessInformationFormBack}
                    onSubmit={this.handleBusinessInformationFormSubmit}
                  />
                </Fieldset>

                {status === applications.STATUS_BANK_AUTH && this.renderBankAuth()}
                {status === applications.STATUS_BANK_ACCOUNTS && this.renderBankAccounts()}

                <Fieldset
                  title={
                    <>
                      <Heading level="1" testid="application--title">
                        {title}
                      </Heading>
                      <SetupStep children={3} />
                      Your bank account information
                    </>
                  }
                  copy={
                    <p>
                      This is where the money is going to be deposited—please enter the business
                      checking account associated with your business.{' '}
                      <Text weight="semibold">
                        Your setup can’t be approved if this information is not verifiable.
                      </Text>
                    </p>
                  }
                  hide={status !== applications.STATUS_BANK_MANUAL}>
                  <BankAccountForm
                    applicationId={applicationId}
                    backButton={<ButtonBack onClick={this.handleBankManualBack} />}
                    isVisible={status === applications.STATUS_BANK_MANUAL}
                    submitButton={<ButtonNext />}
                    onSuccess={this.handleBankManualSubmit}
                  />
                </Fieldset>

                {status === applications.STATUS_SUMMARY && this.renderSummary()}
                {status === applications.STATUS_SUBMITTED && this.renderSubmitted()}
              </ErrorBoundary>
            </Container>
          </View>
        </div>
      </Layout>
    )
  }
}
